import * as React from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import { Query, QueryResult } from 'react-apollo'
import { Link } from 'react-router-dom'
import { Page, Card, Icon, Table } from 'tabler-react'
import { Loading } from '../../components'
import { GetIncomingMailMessages } from '../../types'

const GET_INCOMING_MESSAGES = gql`
  query GetIncomingMailMessages {
    incomingMailMessages {
      id
      subject
      business {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`

export default () => (
  <Query query={GET_INCOMING_MESSAGES} fetchPolicy="cache-and-network">
    {({ loading, data }: QueryResult<GetIncomingMailMessages>) => {
      if (loading && (!data || !data.incomingMailMessages)) return <Loading />
      if (!data || !data.incomingMailMessages)
        throw new Error('Unable to load messages')
      return <IncomingMailList messages={data.incomingMailMessages} />
    }}
  </Query>
)

const IncomingMailList = (props: any) => {
  console.log('IncomingMailList', props)
  const { messages } = props
  return (
    <Page.Content title="Incoming Mail Messages">
      <Card>
        <Table responsive striped highlightRowOnHover cards>
          <Table.Header>
            <Table.Row>
              <Table.ColHeader className="w-1" />
              <Table.ColHeader className="w-25">From</Table.ColHeader>
              <Table.ColHeader className="w-auto">Subject</Table.ColHeader>
              <Table.ColHeader className="w-1">Created</Table.ColHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {messages.map((message: any) => (
              <Table.Row key={message.id}>
                <Table.Col>
                  <Icon prefix="fa" name="circle" className="text-primary" />
                </Table.Col>
                <Table.Col>
                  {message.business &&
                    // needless to link to business.
                    // <Link to={`/businesses/${message.business.id}`}>
                    message.business.name
                  // </Link>
                  }
                </Table.Col>
                {/* give links a larger click area */}
                <Table.Col className="pt-0 pb-0">
                  <Link
                    to={`/incoming-mail/${message.id}`}
                    className="d-block pt-3 pb-3"
                  >
                    {message.subject !== '' ? message.subject : 'No Subject'}
                  </Link>
                </Table.Col>
                <Table.Col alignContent="right text-nowrap">
                  {moment(message.createdAt).format('MMM Do, YYYY, h:mm a')}
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
    </Page.Content>
  )
}

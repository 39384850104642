
import * as React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { match } from 'react-router-dom'
import { Loading } from '../../components'
import { GetBusinessCampaigns } from '../../types'
import CampaignsList from './CampaignsList'

const GET_BUSINESS_CAMPAIGNS = gql`
  query GetBusinessCampaigns($id: ID!) {
    campaigns(business: $id) {
      id
      name
      active
      topics {
        id
        name
      }
    }
  }
`

// { businessId: string; match: Match }
interface Props {
  businessId: string
  match: any
}
export default (props: Props) => {
  console.log('root props', props)
  return (
    <Query
      query={GET_BUSINESS_CAMPAIGNS}
      variables={{ id: props.match.params.id }}
    >
      {({ data, loading }: QueryResult<GetBusinessCampaigns>) => {
        if (loading && (!data || !data.campaigns)) return <Loading />

        if (!data || !data.campaigns)
          throw new Error('Unable to load business calls')

        return (
          <CampaignsList
            businessId={props.businessId}
            campaigns={data.campaigns}
          />
        )
      }}
    </Query>
  )
}


import * as React from 'react'

const LengthDangerMessage = ({ value }: { value: string }) => {
  const diff = 30 - value.length
  return (
    <div className="text-danger">
      {diff >= 0
        ? `Please use 30 characters or less. ${diff} characters remaining.`
        : 'Please shorten your keyword to 30 characters or less'}
    </div>
  )
}

export default LengthDangerMessage


import gql from 'graphql-tag'

export const GET_INDUSTRY_LIST_QUERY = gql`
  query GetIndustryList {
    industries {
      id
      name
      parentId
    }
  }
`

export const GET_TOPIC_KEYWORDS_QUERY = gql`
  query GetTopicKeywords($topic: ID!) {
    libraryTopic(id: $topic) {
      id
      keywords {
        id
        keyword
      }
    }
  }
`

export const GET_INDUSTRY_DETAILS_QUERY = gql`
  query GetIndustryDetails($industry: ID!) {
    libraryIndustry(id: $industry) {
      id
      name
      topics {
        id
        name
        keywords {
          id
          keyword
        }
      }
      taglines {
        id
        text
        isGeneric
      }
    }
  }
`

export const CREATE_TOPIC_KEYWORD_MUTATION = gql`
  mutation AddTopicKeyword($topic: ID!, $keyword: String!) {
    libraryAddTopicKeyword(topic: $topic, keyword: $keyword) {
      errors {
        key
        message
      }
      keyword {
        id
        keyword
      }
    }
  }
`

export const UPDATE_KEYWORD_MUTATION = gql`
  mutation UpdateKeyword($id: ID!, $keyword: String!) {
    libraryUpdateKeyword(id: $id, keyword: $keyword) {
      errors {
        key
        message
      }
      keyword {
        id
        keyword
      }
    }
  }
`

export const DELETE_KEYWORD_MUTATION = gql`
  mutation DeleteKeyword($id: ID!) {
    libraryDeleteKeyword(id: $id) {
      errors {
        key
        message
      }
      keyword {
        id
      }
    }
  }
`

export const CREATE_TAGLINE_MUTATION = gql`
  mutation CreateTagLine($text: String!, $industry: ID!) {
    libraryAddTagLine(text: $text, industry: $industry) {
      errors {
        key
        message
      }
      tagLine {
        id
        text
        isGeneric
        createdAt
        updatedAt
      }
    }
  }
`

export const UPDATE_TAGLINE_MUTATION = gql`
  mutation UpdateTagLine($id: ID!, $text: String!) {
    libraryUpdateTagLine(id: $id, text: $text) {
      errors {
        key
        message
      }
      tagLine {
        id
        text
        isGeneric
      }
    }
  }
`

export const DELETE_TAGLINE_MUTATION = gql`
  mutation DeleteTagLine($id: ID!) {
    libraryDeleteTagLine(id: $id) {
      errors {
        key
        message
      }
      tagLine {
        id
      }
    }
  }
`

export const CREATE_TOPIC_MUTATION = gql`
  mutation CreateTopic($topic: String!, $industry: ID!) {
    libraryAddTopic(topic: $topic, industry: $industry) {
      errors {
        key
        message
      }
      topic {
        id
        name
        keywords {
          id
        }
      }
    }
  }
`

export const UPDATE_TOPIC_MUTATION = gql`
  mutation UpdateTopic($id: ID!, $topic: String!) {
    libraryUpdateTopic(id: $id, topic: $topic) {
      errors {
        key
        message
      }
      topic {
        id
        name
      }
    }
  }
`

export const DELETE_TOPIC_MUTATION = gql`
  mutation DeleteTopic($id: ID!) {
    libraryDeleteTopic(id: $id) {
      errors {
        key
        message
      }
      topic {
        id
      }
    }
  }
`

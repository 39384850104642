
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Alert, FormCard, FormTextInput } from 'tabler-react'
import { StandaloneFormPage } from '../../components'

type Props = {
  email: string
  password: string
  error: string | null | undefined
  onSubmit: Function
  onChangeField: Function
}

const LoginForm = ({
  email,
  password,
  error,
  onSubmit,
  onChangeField,
}: Props) => (
  <StandaloneFormPage>
    <FormCard
      buttonText="Login"
      title="Login to your Account"
      onSubmit={onSubmit}
    >
      {error && <Alert type="danger">{error}</Alert>}

      <FormTextInput
        name="email"
        type="email"
        label="Email Address"
        placeholder="Enter Email"
        onChange={onChangeField}
      />

      <FormTextInput
        name="password"
        type="password"
        label="Password"
        placeholder="Password"
        onChange={onChangeField}
      />
    </FormCard>

    <div className="text-center text-muted">
      Don't have account yet? <Link to="/signup">Sign up</Link>
    </div>
  </StandaloneFormPage>
)

export default LoginForm

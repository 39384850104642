import React from 'react'
import Lottie from 'lottie-react-web'
import { Page, Grid } from 'tabler-react'
import animation from './done-button.json'

export default function ConnectToGoogleSuccess() {
  return (
    <Page.Content>
      <Page.Header>
        <Page.Title className="m-auto">
          Google Ads Account Connected!
        </Page.Title>
      </Page.Header>
      <Grid.Row>
        <Grid.Col md={8} offsetMd={2} lg={6} offsetLg={3}>
          <p style={{ textAlign: 'center' }}>
            Your google ads account has succesfully been connected to
            MarketZipper.
          </p>
          <Lottie
            speed={0.5}
            options={{
              animationData: animation,
              loop: false,
            }}
          />
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  )
}


import * as React from 'react'
import { List, Card, Button } from 'tabler-react'
import { Query, QueryResult, compose, graphql } from 'react-apollo'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Loading } from '../../components'
import { GetIndustryDetails } from '../../types'
import {
  GET_INDUSTRY_DETAILS_QUERY,
  CREATE_TAGLINE_MUTATION,
  UPDATE_TAGLINE_MUTATION,
  DELETE_TAGLINE_MUTATION,
  CREATE_TOPIC_MUTATION,
  UPDATE_TOPIC_MUTATION,
  DELETE_TOPIC_MUTATION,
} from './queries'
import AddTopicForm from './AddTopicForm'
import AddTagLineForm from './AddTagLineForm'
import TopicRow from './TopicRow'
import TagLineRow from './TagLineRow'

class TopicTagLineColumnInner extends React.Component<any, any> {
  state = {
    viewMode: 'topics',
  }

  handleAddTopic = (topic: any) => {
    this.props.createTopic({
      variables: {
        topic,
        industry: this.props.industryId,
      },
    })
  }

  handleUpdateTopic = (id: any, topic: any) => {
    this.props.updateTopic({
      variables: {
        id,
        topic,
      },
    })
  }

  handleDeleteTopic = (id: any) => {
    // if we are on the URL for the topic, redirect
    const { location, history, industryId } = this.props
    if (location.pathname.includes(`/topics/${id}`)) {
      history.push(`/library/industries/${industryId}`)
    }

    this.props.deleteTopic({
      variables: { id },
    })
  }

  handleAddTagLine = (tagLine: any) => {
    this.props.createTagLine({
      variables: {
        text: tagLine,
        industry: this.props.industryId,
      },
    })
  }

  handleUpdateTagLine = (id: any, tagLine: any) => {
    this.props.updateTagLine({
      variables: {
        id,
        text: tagLine,
      },
    })
  }

  handleDeleteTagLine = (id: any) => {
    this.props.deleteTagLine({
      variables: { id },
    })
  }

  render() {
    const { industryId, topics, tagLines } = this.props
    const { viewMode } = this.state
    return (
      <Card
        title={viewMode === 'topics' ? 'Topics' : 'Tag Lines'}
        options={
          <Button.List>
            <Button
              size="sm"
              onClick={() => this.setState({ viewMode: 'topics' })}
              {...(viewMode === 'topics'
                ? { color: 'primary' }
                : { link: true })}
            >
              Topics
            </Button>
            <Button
              size="sm"
              onClick={() => this.setState({ viewMode: 'taglines' })}
              {...(viewMode === 'taglines'
                ? { color: 'primary' }
                : { link: true })}
            >
              Tag Lines
            </Button>
          </Button.List>
        }
      >
        <Card.Body className="p-0">
          {viewMode === 'topics' && (
            <List.Group className="list-group-flush">
              {topics.length < 1 && (
                <List.GroupItem className="my-5 text-center text-danger">
                  There are no topics
                </List.GroupItem>
              )}
              {topics.map((topic: any) => (
                <TopicRow
                  key={topic.id}
                  industryId={industryId}
                  topic={topic}
                  onUpdateTopic={this.handleUpdateTopic}
                  onDeleteTopic={this.handleDeleteTopic}
                />
              ))}
            </List.Group>
          )}
          {viewMode === 'taglines' && (
            <List.Group className="list-group-flush">
              {tagLines.length < 1 && (
                <List.GroupItem className="py-5 text-center text-danger">
                  There are no tag lines
                </List.GroupItem>
              )}
              {tagLines.map((tagLine: any) => (
                <TagLineRow
                  key={tagLine.id}
                  industryId={industryId}
                  tagLine={tagLine}
                  onUpdateTagLine={this.handleUpdateTagLine}
                  onDeleteTagLine={this.handleDeleteTagLine}
                />
              ))}
            </List.Group>
          )}
        </Card.Body>
        {viewMode === 'topics' && (
          <AddTopicForm onSubmit={this.handleAddTopic} />
        )}
        {viewMode === 'taglines' && (
          <AddTagLineForm onSubmit={this.handleAddTagLine} />
        )}
      </Card>
    )
  }
}

const TopicTagLineColumnWrapper = compose(
  graphql(CREATE_TOPIC_MUTATION, {
    name: 'createTopic',
    // @ts-ignore
    options: ({ industryId }) => ({
      // @ts-ignore
      update(store, { data: { libraryAddTopic } }: Object) {
        const data = store.readQuery({
          query: GET_INDUSTRY_DETAILS_QUERY,
          variables: { industry: industryId },
        })
        // @ts-ignore
        if (!data || !data.libraryIndustry) {
          throw new Error('Unable to read industry data from storage')
        }
        // @ts-ignore
        data.libraryIndustry.topics.push(libraryAddTopic.topic)
        store.writeQuery({
          query: GET_INDUSTRY_DETAILS_QUERY,
          data,
        })
      },
    }),
  }),
  graphql(UPDATE_TOPIC_MUTATION, { name: 'updateTopic' }),
  graphql(DELETE_TOPIC_MUTATION, {
    name: 'deleteTopic',
    // @ts-ignore
    options: ({ industryId }) => ({
      // @ts-ignore
      update(store, { data: { libraryDeleteTopic } }: Object) {
        const data = store.readQuery({
          query: GET_INDUSTRY_DETAILS_QUERY,
          variables: { industry: industryId },
        })

        // @ts-ignore
        if (!data || !data.libraryIndustry) {
          throw new Error('Unable to read industry data from storage')
        }

        // @ts-ignore
        data.libraryIndustry.topics = data.libraryIndustry.topics.filter(
          (x: any) => x.id !== libraryDeleteTopic.topic.id
        )
        store.writeQuery({
          query: GET_INDUSTRY_DETAILS_QUERY,
          data,
        })
      },
    }),
  }),
  graphql(CREATE_TAGLINE_MUTATION, {
    name: 'createTagLine',
    // @ts-ignore
    options: ({ industryId }) => ({
      // @ts-ignore
      update(store, { data: { libraryAddTagLine } }: Object) {
        const data = store.readQuery({
          query: GET_INDUSTRY_DETAILS_QUERY,
          variables: { industry: industryId },
        })

        // @ts-ignore
        if (!data || !data.libraryIndustry) {
          throw new Error('Unable to read industry data from storage')
        }

        // @ts-ignore
        data.libraryIndustry.taglines.push(libraryAddTagLine.tagLine)
        store.writeQuery({
          query: GET_INDUSTRY_DETAILS_QUERY,
          data,
        })
      },
    }),
  }),
  graphql(UPDATE_TAGLINE_MUTATION, { name: 'updateTagLine' }),
  graphql(DELETE_TAGLINE_MUTATION, {
    name: 'deleteTagLine',
    // @ts-ignore
    options: ({ industryId }) => ({
      // @ts-ignore
      update(store, { data: { libraryDeleteTagLine } }: Object) {
        const data = store.readQuery({
          query: GET_INDUSTRY_DETAILS_QUERY,
          variables: { industry: industryId },
        })

        // @ts-ignore
        if (!data || !data.libraryIndustry) {
          throw new Error('Unable to read industry data from storage')
        }

        // @ts-ignore
        data.libraryIndustry.taglines = data.libraryIndustry.taglines.filter(
          (x: any) => x.id !== libraryDeleteTagLine.tagLine.id
        )
        store.writeQuery({
          query: GET_INDUSTRY_DETAILS_QUERY,
          data,
        })
      },
    }),
  })
)(TopicTagLineColumnInner)

interface TopicTagLineColumnProps extends RouteComponentProps {
  industryId: string
  industry: any
}

const TopicTagLineColumn = withRouter((props: TopicTagLineColumnProps) => {
  const industryId: string = props.industryId
  return (
    <Query
      query={GET_INDUSTRY_DETAILS_QUERY}
      variables={{ industry: industryId }}
    >
      {({ data, loading }: QueryResult<GetIndustryDetails>) => {
        if (loading && (!data || !data.libraryIndustry)) return <Loading />

        if (!data || !data.libraryIndustry)
          throw new Error('Unable to load industry details')

        return (
          <TopicTagLineColumnWrapper
            {...props}
            topics={data.libraryIndustry.topics}
            tagLines={data.libraryIndustry.taglines}
          />
        )
      }}
    </Query>
  )
})

export default TopicTagLineColumn

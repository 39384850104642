import * as React from 'react'
import { Icon } from 'tabler-react'

export default ({ brand, ...props }: { brand: string; className?: string }) => (
  <Icon payment name={brandToClassName(brand)} {...props} />
)

//
// http://tabler-react.com/icons
//
const brandToClassName = (brand: string): string => {
  switch (brand.toLowerCase()) {
    case 'amex':
    case 'american express':
    case 'americanexpress':
      return 'americanexpress'

    case 'discover':
      return 'discover'

    case 'mastercard':
      return 'mastercard'

    case 'visa':
      return 'visa'

    default:
      return 'fas fa-credit-card'
  }
}

import React from 'react'
import { Page, Grid, Button, Card } from 'tabler-react'

export default function NoAdsAccountFound() {
  return (
    <Page.Content>
      <Page.Header>
        <Page.Title className="m-auto">
          You do not have a Google Ads Account
        </Page.Title>
      </Page.Header>
      <Grid.Row>
        <Grid.Col md={8} offsetMd={2} lg={6} offsetLg={3}>
          <Card statusColor="red">
            <Card.Body>
              <p>
                You must have a{' '}
                <a href="https://ads.google.com/" target="_blank">
                  Google Ads
                </a>{' '}
                account to continue.
              </p>

              {/* Needs to link to help doc on marketzipper.com */}
              <Button
                color="primary"
                block
                size="lg"
                RootComponent="a"
                href="#"
              >
                Help me setup an account
              </Button>

              <Button color="secondary" block>
                I have setup an account on my own
              </Button>
            </Card.Body>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  )
}

import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Page, Alert, Grid } from 'tabler-react'
import { UserDashboardData } from '../../../types'
import { UserContext, Loading } from '../../../components'
import BusinessInfoCard from './BusinessInfoCard'
import SetupAndConnectionsCard from './SetupAndConnectionsCard'
import ActivityStatsCard from './ActivityStatsCard'
import CampaignStats from './CampaignStats'

const DASHBOARD_QUERY = gql`
  query UserDashboardData {
    businesses {
      edges {
        node {
          id
          name
          logo
          phoneNumber
          webAddress
          streetAddress
          city
          state
          postalCode
          accounts {
            gaw {
              isConnected
            }
          }
          campaigns {
            id
            name
          }
          onboardingStatus {
            complete
            wizardStatus {
              overallProgress
            }
          }
          paymentMethods {
            id
            isDefault
            brand
            last4
          }
          subscription {
            id
            name
          }
        }
      }
    }
    viewer {
      id
      name
      lastLoginAt
      lastWebLoginAt
      lastMobileLoginAt
    }
  }
`

export default function Dashboard() {
  const { user } = useContext(UserContext)
  const { data, loading } = useQuery<UserDashboardData>(DASHBOARD_QUERY)

  if (!user.authenticated) throw new Error('A user is required for this page')
  if (loading && (!data || !data.businesses)) return <Loading />
  if (!data || !data.businesses) throw new Error('Unable to load business list')

  const businessList = data.businesses.edges.map((edge) => edge.node)
  const campaigns = businessList.flatMap((biz) => biz.campaigns)

  const showCampaignStats =
    businessList.length > 0 ? businessList[0].onboardingStatus.complete : false

  return (
    <Page.Content title="User Dashboard">
      {businessList.length < 1 ? (
        <Alert type="warning" icon="alert-triangle" className="mb-0">
          No Businesses found
        </Alert>
      ) : (
        <Grid.Row cards deck>
          {businessList.map((business) => (
            <React.Fragment key={business.id}>
              <Grid.Col width={12} md={6} xl={4}>
                <BusinessInfoCard business={business} />
              </Grid.Col>

              <Grid.Col width={12} md={6} xl={4}>
                <SetupAndConnectionsCard
                  business={business}
                  viewer={data.viewer}
                />
              </Grid.Col>

              {/**
               * TODO - add real data and expose again
               * Temporarily hidden when they have finished onboarding
               **/}
              {!business.onboardingStatus.complete && (
                <Grid.Col width={12} md={6} xl={4}>
                  <ActivityStatsCard status={business.onboardingStatus} />
                </Grid.Col>
              )}
            </React.Fragment>
          ))}
        </Grid.Row>
      )}

      {showCampaignStats && <CampaignStats campaigns={campaigns} />}
    </Page.Content>
  )
}

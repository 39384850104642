import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { StripeProvider, Elements } from 'react-stripe-elements'
import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'
import { STRIPE_PUBLIC_KEY } from '../../../config'
import PaymentForm from './PaymentForm'

const CREATE_PAYMENT_METHOD = gql`
  mutation CreatePaymentMethod($input: PaymentMethodCreateInput!) {
    addPaymentMethod(input: $input) {
      id
    }
  }
`

interface Props extends RouteComponentProps<{ business: string }> {}

interface State {
  stripe: object | null | undefined
}

export default class UserAddPaymentMethod extends React.Component<
  Props,
  State
> {
  state = {
    stripe: null,
  }

  componentDidMount() {
    if ('Stripe' in window) {
      this.setState({ stripe: (window as any).Stripe(STRIPE_PUBLIC_KEY) })
    }
    // if (!window.Stripe) return
  }

  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <Mutation mutation={CREATE_PAYMENT_METHOD}>
            {(addPaymentMethod) => {
              return (
                <PaymentForm
                  {...this.props}
                  addPaymentMethod={addPaymentMethod}
                />
              )
            }}
          </Mutation>
        </Elements>
      </StripeProvider>
    )
  }
}

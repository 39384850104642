import * as React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import decodeJWT from 'jwt-decode'
import { UserProvider, UserContextType, UserInfo } from './components'
import apolloClient from './apollo-client'

import AgentApplication from './apps/AgentApplication'
import UserApplication from './apps/UserApplication'
import LoginApplication from './apps/LoginApplication'

type Props = {}
type State = { userData: UserContextType }

export default class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    let user = { authenticated: false }

    // JSDOM does not have localStorage available...
    if (typeof localStorage !== 'undefined') {
      const localToken = localStorage.getItem('mz/auth')
      if (localToken) {
        const decoded = decodeJWT(localToken)
        if (decoded) {
          user = { authenticated: true, ...decoded }
        }
      }
    }

    this.state = {
      userData: {
        // @ts-ignore
        user,
        setUser: this.setUser,
      },
    }
  }

  setUser = (user: UserInfo) => {
    const { userData } = this.state
    this.setState({ userData: { ...userData, user } })
  }

  render() {
    const {
      userData: { user },
    } = this.state
    return (
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
          <UserProvider value={this.state.userData}>
            <BrowserRouter>
              {user.authenticated && user.role ? (
                user.role === 'customer' ? (
                  <UserApplication />
                ) : (
                  <AgentApplication />
                )
              ) : (
                <LoginApplication />
              )}
            </BrowserRouter>
          </UserProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    )
  }
}

import * as React from 'react'
import { Link } from 'react-router-dom'
import { Card, Button, Grid, Icon, List, Tag, Text, Media } from 'tabler-react'
import { Query, Mutation, QueryResult } from 'react-apollo'
import gql from 'graphql-tag'
import { CardBrandIcon } from '../../../components'
import { GetUserPaymentMethods } from '../../../types'

const PAYMENT_METHOD_QUERY = gql`
  query GetUserPaymentMethods($business: ID!) {
    paymentMethods(business: $business) {
      id
      name
      brand
      expMonth
      expYear
      isDefault
    }
  }
`

const SET_DEFAULT_PAYMENT_METHOD = gql`
  mutation setDefaultPaymentMethod($business: ID!, $paymentMethod: ID!) {
    setDefaultPaymentMethod(
      business: $business
      paymentMethod: $paymentMethod
    ) {
      id
    }
  }
`

const REMOVE_PAYMENT_METHOD = gql`
  mutation removePaymentMethod($business: ID!, $paymentMethod: ID!) {
    removePaymentMethod(business: $business, paymentMethod: $paymentMethod) {
      id
    }
  }
`

const PaymentMethodList = ({ businessId }: { businessId: string }) => (
  <Query query={PAYMENT_METHOD_QUERY} variables={{ business: businessId }}>
    {({ data, loading, refetch }: QueryResult<GetUserPaymentMethods>) => {
      if (loading && (!data || !data.paymentMethods))
        return (
          <div className="text-center text-muted">
            Loading Payment Methods...
          </div>
        )

      if (!data || !data.paymentMethods)
        throw new Error('Unable to load payment methods')

      const { paymentMethods } = data
      const canRemove = paymentMethods.length > 1
      return (
        <Grid.Row>
          {paymentMethods.length < 1 && (
            <Grid.Col width={12} className="text-muted mb-5">
              Add a payment method to your Market Zipper Account to get started
              with Advertising.
            </Grid.Col>
          )}

          <Grid.Col xsAuto>
            <Link
              to={`/businesses/${businessId}/payment-methods/new`}
              className="btn btn-lg btn-outline-primary d-flex align-items-center justify-content-center"
              style={{
                flexGrow: 1,
                marginBottom: '1.5rem',
                borderStyle: 'dashed',
                minWidth: 140,
                minHeight: 63,
              }}
            >
              <Icon name="plus" /> Add a Card
            </Link>
          </Grid.Col>

          {paymentMethods.map((method) => (
            <Grid.Col key={method.id}>
              <Card
                isCollapsible
                isCollapsed
                statusSide
                statusColor={method.isDefault ? 'success' : null}
                title={
                  <Media>
                    <Media.Object className="mr-3">
                      <CardBrandIcon brand={method.brand} />
                    </Media.Object>
                    <Media.Body>
                      <div className="d-flex align-items-center">
                        <h5 className="text-nowrap m-0">{method.name}</h5>
                        {method.isDefault && (
                          <Tag color="success" className="ml-3">
                            Default
                          </Tag>
                        )}
                      </div>
                      <small className="mt-0 mb-0 small text-muted text-nowrap">
                        Expires: {`${method.expMonth}/${method.expYear}`}
                      </small>
                    </Media.Body>
                  </Media>
                }
              >
                <List.Group isCardBody>
                  <List.GroupItem>
                    <Button
                      outline
                      color="primary"
                      size="sm"
                      icon="edit"
                      className="float-right"
                    >
                      Edit
                    </Button>

                    <Text muted className="small text-uppercase">
                      Name on card
                    </Text>
                    <Text className="text-danger">John Doe</Text>
                    <Text muted className="small text-uppercase mt-3">
                      Billing address
                    </Text>
                    <address className="mb-0 text-danger">
                      300 Main St, <br />
                      Buffalo, NY 12345
                    </address>
                  </List.GroupItem>

                  <List.GroupItem className="d-flex justify-content-between">
                    <Mutation mutation={REMOVE_PAYMENT_METHOD}>
                      {(removePaymentMethod) => (
                        <Button
                          link
                          disabled={
                            !method.isDefault && canRemove ? false : true
                          }
                          size="sm"
                          icon="trash"
                          className={
                            !method.isDefault && canRemove
                              ? 'text-danger'
                              : 'text-secondary'
                          }
                          onClick={async (
                            e: React.SyntheticEvent<HTMLButtonElement>
                          ) => {
                            e.preventDefault()

                            // eslint-disable-next-line
                            if (
                              !confirm(
                                'Are you sure you want to Delete this card?'
                              )
                            ) {
                              return
                            }

                            await removePaymentMethod({
                              variables: {
                                business: businessId,
                                paymentMethod: method.id,
                              },
                            })
                            // TODO just update the cache entry for the query
                            refetch()
                          }}
                        >
                          Delete
                        </Button>
                      )}
                    </Mutation>

                    <Mutation mutation={SET_DEFAULT_PAYMENT_METHOD}>
                      {(setDefaultPaymentMethod) => (
                        <Button
                          disabled={!method.isDefault ? false : true}
                          color={!method.isDefault ? 'success' : 'secondary'}
                          size="sm"
                          icon="check"
                          className="m-0"
                          onClick={async (
                            e: React.SyntheticEvent<HTMLButtonElement>
                          ) => {
                            e.preventDefault()
                            await setDefaultPaymentMethod({
                              variables: {
                                business: businessId,
                                paymentMethod: method.id,
                              },
                            })
                            // TODO just update the cache entry for the query
                            refetch()
                          }}
                        >
                          Make Default
                        </Button>
                      )}
                    </Mutation>
                  </List.GroupItem>
                </List.Group>
              </Card>
            </Grid.Col>
          ))}
        </Grid.Row>
      )
    }}
  </Query>
)

export default PaymentMethodList

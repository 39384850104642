import * as React from 'react'
import { Mutation, MutationFn } from 'react-apollo'
import gql from 'graphql-tag'
import { UserConsumer } from '../../components'
import LoginForm from './LoginForm'
import { Login, LoginVariables } from '../../types'

const LOGIN_MUTATION = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password, source: "web") {
      token
      user {
        id
        email
        name
        role
      }
    }
  }
`

type Props = {
  history: any
  setUser: Function
  login: MutationFn<Login, LoginVariables>
}

type State = {
  email: string
  password: string
  error: string | null | undefined
}

class LoginWrapper extends React.Component<Props, State> {
  state = {
    error: null,
    email: '',
    password: '',
  }

  handleChangeField = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget
    // @ts-ignore
    this.setState({ [name]: value })
  }

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { email, password } = this.state

    try {
      const response = await this.props.login({
        variables: {
          email,
          password,
        },
      })

      console.log('response', response)
      if (!response || !response.data) {
        throw new Error('login failed to return appropriate data')
      }

      const { token, user } = response.data.login
      localStorage.setItem('mz/auth', token)
      this.props.setUser({
        authenticated: true,
        ...user,
      })
      this.props.history.push('/dashboard')
    } catch (err) {
      this.setState({ error: 'Login Failed. Please try again' })
      return
    }
  }

  render() {
    return (
      <LoginForm
        email={this.state.email}
        password={this.state.password}
        error={this.state.error}
        onSubmit={this.handleSubmit}
        onChangeField={this.handleChangeField}
      />
    )
  }
}

export default (props: any) => (
  <UserConsumer>
    {({ setUser }) => (
      <Mutation mutation={LOGIN_MUTATION}>
        {(login: MutationFn<Login, LoginVariables>) => (
          <LoginWrapper {...props} setUser={setUser} login={login} />
        )}
      </Mutation>
    )}
  </UserConsumer>
)

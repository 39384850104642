import * as React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { match } from 'react-router-dom'
import { Loading } from '../../../components'
import { GetUserBusinessDetails } from '../../../types'
import BusinessDetails from './BusinessDetails'

const GET_BUSINESS_DETAILS = gql`
  query GetUserBusinessDetails($id: ID!) {
    business(id: $id) {
      id
      name
      webAddress
      phoneNumber
      logo
      streetAddress
      city
      state
      postalCode
      accounts {
        gaw {
          id
          isConnected
        }
      }
    }
  }
`

interface Props {
  match: match<{ id: string }>
}

export default (props: Props) => {
  return (
    <Query
      query={GET_BUSINESS_DETAILS}
      variables={{ id: props.match.params.id }}
    >
      {({ data, loading }: QueryResult<GetUserBusinessDetails>) => {
        if (loading && (!data || !data.business)) return <Loading />

        if (!data || !data.business)
          throw new Error('Unable to load business information')

        return <BusinessDetails business={data.business} />
      }}
    </Query>
  )
}

import qs from 'qs'

export function getRedirectUrl(): string {
  return `https://app.marketzipper.com/connect-to-google`
}

/**
 * Parse the query string for code & state params from google oauth flow
 */
export function getGoogleAuthParams(
  query: string
): { authCode: string; businessId: string } {
  const params = qs.parse(query, { ignoreQueryPrefix: true })

  if (!params) {
    throw new Error('Unable to locate authentication parameters')
  }

  if (!params.code || !params.state) {
    throw new Error('Missing mandatory authentication parameters')
  }

  const state = JSON.parse(atob(params.state))
  if (!state || !state.businessId) {
    throw new Error(
      'Unable to determine business from authentication parameters'
    )
  }

  return {
    authCode: params.code,
    businessId: state.businessId,
  }
}

export function getGawOauthUrl(businessId: string): string {
  const clientId =
    '263116696745-4m9t8rcjomep623o9uv9b50l318ad128.apps.googleusercontent.com'

  const authUrl =
    `https://accounts.google.com/o/oauth2/v2/auth?` +
    `&client_id=${clientId}` +
    `&redirect_uri=${encodeURIComponent(getRedirectUrl())}` +
    `&response_type=code` +
    `&access_type=offline` +
    `&prompt=consent` +
    `&scope=${encodeURIComponent('https://www.googleapis.com/auth/adwords')}` +
    `&state=${btoa(JSON.stringify({ businessId }))}`

  return authUrl
}


import * as React from 'react'
import { Query, QueryResult } from 'react-apollo'
import gql from 'graphql-tag'
import Inspector from 'react-json-inspector'
import { match } from 'react-router-dom'
import { Card, Table } from 'tabler-react'
import { Loading } from '../../components'
import { GetCallDetails } from '../../types'

import 'react-json-inspector/json-inspector.css'

const GET_CALL_DETAILS = gql`
  query GetCallDetails($id: ID!) {
    call(id: $id) {
      id
      duration
      callerId
      fromNumber
      recordingUrl
      events
    }
  }
`

interface Props {
  match: match<{ call: string }>
  call: any
}

export default ({ match }: Props) => (
  <Query query={GET_CALL_DETAILS} variables={{ id: match.params.call }}>
    {({ loading, data }: QueryResult<GetCallDetails>) => {
      if (loading && (!data || !data.call)) return <Loading />
      if (!data || !data.call) throw new Error('Unable to load call')
      return <CallDetails call={data.call} />
    }}
  </Query>
)

const CallDetails = (props: any) => {
  console.log('CallDetails', props)
  const { call } = props
  return (
    <React.Fragment>
      <Card title="Call Details">
        <Card.Body>
          <Table responsive cards>
            <Table.Row>
              <Table.ColHeader>From</Table.ColHeader>
              <Table.Col>{call.fromNumber}</Table.Col>
            </Table.Row>
            <Table.Row>
              <Table.ColHeader>Duration</Table.ColHeader>
              <Table.Col>{call.duration}</Table.Col>
            </Table.Row>
            <Table.Row>
              <Table.ColHeader>Caller ID</Table.ColHeader>
              <Table.Col>{call.callerId || 'Not Available'}</Table.Col>
            </Table.Row>
            {call.recordingUrl && (
              <Table.Row>
                <Table.ColHeader>Recording</Table.ColHeader>
                <Table.Col>
                  <audio src={call.recordingUrl} controls preload="none" />
                </Table.Col>
              </Table.Row>
            )}
          </Table>
        </Card.Body>
      </Card>
      <Card title="Call Events">
        <Card.Body>
          <Inspector data={call.events} />
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

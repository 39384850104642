
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import 'tabler-react/dist/Tabler.css'
import './styles/overrides.css'

const root = document.getElementById('root')

if (!root) {
  throw new Error('Unable to find mount node')
}

ReactDOM.render(<App />, root)

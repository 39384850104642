import React from 'react'
import { Card, Grid, Icon, List, Media, Text } from 'tabler-react'
import { UserDashboardData_businesses_edges_node } from '../../../types'

interface Props {
  business: UserDashboardData_businesses_edges_node
}

export default function BusinessInfoCard({ business }: Props) {
  return (
    <Card>
      <List.Group isCardBody className="flex-grow-1">
        <List.GroupItem
          action
          to={`/businesses/${business.id}`}
          className="d-flex flex-grow-1 align-items-center"
        >
          <Media className="d-flex flex-grow-1">
            <Media.Object className="mr-3">
              <img
                width={64}
                src={
                  business.logo !== null
                    ? business.logo
                    : '/images/placeholder-company.png'
                }
                className="rounded"
              />
            </Media.Object>
            <Media.Body>
              <h4 className="mb-1">{business.name}</h4>
              <address className="mb-2 text-muted small">
                {business.streetAddress}
                {business.city && (
                  <React.Fragment>
                    <br />
                    {business.city}
                    {business.state && (
                      <React.Fragment>, {business.state}</React.Fragment>
                    )}{' '}
                    {business.postalCode}
                  </React.Fragment>
                )}
              </address>
            </Media.Body>
          </Media>
          <Icon name="chevron-right" />
        </List.GroupItem>

        {(business.phoneNumber || business.webAddress) && (
          <List.GroupItem RootComponent="li">
            <Grid.Row>
              {business.phoneNumber && (
                <Grid.Col>
                  <Text className="text-body small">
                    {business.phoneNumber}
                  </Text>
                </Grid.Col>
              )}
              {business.webAddress && (
                <Grid.Col>
                  <Text className="text-body small">{business.webAddress}</Text>
                </Grid.Col>
              )}
            </Grid.Row>
          </List.GroupItem>
        )}

        <List.GroupItem RootComponent="li">
          <Text>Get the Mobile App</Text>
          <Grid.Row>
            <Grid.Col width={5}>
              <Text.Small muted className="mb-1">
                for iPhone
              </Text.Small>
              <a href="" target="_blank">
                <img src="/images/download-app-store.png" alt="App Store" />
              </a>
            </Grid.Col>
            <Grid.Col width={5} offset={2}>
              <Text.Small muted className="mb-1">
                for Android
              </Text.Small>
              <a href="" target="_blank">
                <img src="/images/download-play-store.png" alt="Play Store" />
              </a>
            </Grid.Col>
          </Grid.Row>
        </List.GroupItem>
      </List.Group>
    </Card>
  )
}

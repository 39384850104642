import * as React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { match } from 'react-router-dom'
import { Loading } from '../../components'
import { GetBusinessDetails } from '../../types'
import BusinessMain from './BusinessMain'

const GET_BUSINESS_DETAILS = gql`
  query GetBusinessDetails($id: ID!) {
    business: business(id: $id) {
      id
      name
      webAddress
      phoneNumber
    }
    zipPages: zipPages(businessId: $id) {
      id
      name
      url
    }
  }
`

export type BusinessDetailsT = any //$PropertyType<GetBusinessDetails, 'business'>
export type ZipPageList = any //$PropertyType<GetBusinessDetails, 'zipPages'>

//{ m m}
export default (props: any) => {
  console.log('root props', props)
  return (
    <Query
      query={GET_BUSINESS_DETAILS}
      variables={{ id: props.match.params.id }}
    >
      {({ data, loading }: QueryResult<GetBusinessDetails>) => {
        if (loading && (!data || !data.business)) return <Loading />

        if (!data || !data.business)
          throw new Error('Unable to load business details')

        return (
          <BusinessMain business={data.business} zipPages={data.zipPages} />
        )
      }}
    </Query>
  )
}

import * as React from 'react'
import { Button, Card, Page } from 'tabler-react'

const DownloadButton = () => (
  <Button
    color="success"
    RootComponent="a"
    href="https://app.marketzipper.com/mz-mobile-81b6d0d22d5b4b00a7e675d430ae86b8-signed.apk"
  >
    Download &amp; Install MarketZipper
  </Button>
)

const SignUpComplete = () => (
  <Page.Content title="Thanks!">
    <Card>
      <Card.Body>
        <h2>Installation Instructions</h2>
        <ul>
          <li>
            <a href="#android-9">Android 9 (Pie)</a>
          </li>
          <li>
            <a href="#android-8">Android 8 (Oreo)</a>
          </li>
          <li>
            <a href="#android-6">Android 6 (Marshmallow)</a>
          </li>
          <li>
            <a href="#ios">Apple iOS</a>
          </li>
        </ul>
      </Card.Body>
    </Card>

    <div id="android-9">
      <Card title="Android 9 (Pie)">
        <Card.Body>
          <h5>Allow Installation of APK files</h5>
          <ol>
            <li>Go to Settings</li>
            <li>Tap "Apps &amp; Notifications"</li>
            <li>Tap "Advanced"</li>
            <li>Tap "Special App Access" at bottom of screen</li>
            <li>Tap "Install Unknown Apps"</li>
            <li>Select "Chrome"</li>
            <li>
              Enable <b>"Allow from this source"</b>
            </li>
          </ol>
        </Card.Body>
        <Card.Footer>
          <DownloadButton />
        </Card.Footer>
      </Card>
    </div>

    <div id="android-8">
      <Card title="Android 8 (Orea)">
        <Card.Body>
          <h5>Allow Installation of APK files</h5>
          <ol>
            <li>Go to Settings</li>
            <li>Tap "Apps &amp; Notifications"</li>
            <li>Tap "Advanced"</li>
            <li>Tap "Special App Access" at bottom of screen</li>
            <li>Tap "Install Unknown Apps"</li>
            <li>Select "Chrome"</li>
            <li>
              Enable <b>"Allow from this source"</b>
            </li>
          </ol>
        </Card.Body>
        <Card.Footer>
          <DownloadButton />
        </Card.Footer>
      </Card>
    </div>

    <div id="android-6">
      <Card title="Android 6 (Marshmallow)">
        <Card.Body>
          <h5>Allow Installation of APK files</h5>
          <ol>
            <li>Go to Settings</li>
            <li>Tap "Lock Screen &amp; Security" or "Security"</li>
            <li>Enable "Unknown Sources"</li>
            <li>Confirm that you want to enable unknown sources.</li>
          </ol>
        </Card.Body>
        <Card.Footer>
          <DownloadButton />
        </Card.Footer>
      </Card>
    </div>

    <div id="ios">
      <Card title="Apple iOS">
        <Card.Body>
          <p>
            If you submitted the survey form, you will get an invite to our
            AppStore Connect account, and then TestFlight. Accept both. If you
            didn't fill out the survey, email Joel or Eric.
          </p>
        </Card.Body>
      </Card>
    </div>
  </Page.Content>
)

export default SignUpComplete

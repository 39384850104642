import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Link } from 'react-router-dom'
import { Page, Card, Grid, Icon, List, Media, Alert, Text } from 'tabler-react'
import { Loading, UserContext } from '../../../components'
import {
  GetUserBusinessList,
  GetUserBusinessList_user_businesses,
  GetUserBusinessListVariables,
} from '../../../types'

const LOAD_BUSINESS_LIST = gql`
  query GetUserBusinessList($user: ID!) {
    user(id: $user) {
      id
      businesses {
        id
        name
        logo
        phoneNumber
        webAddress
        streetAddress
        city
        state
        postalCode
        campaigns {
          id
          budgetDaily
          active
        }
        createdAt
      }
    }
  }
`

export default function UserBusinessListPage() {
  const { user } = useContext(UserContext)

  if (!user.authenticated) {
    throw new Error('A user is required for this page')
  }

  const { data, loading } = useQuery<
    GetUserBusinessList,
    GetUserBusinessListVariables
  >(LOAD_BUSINESS_LIST, {
    variables: {
      user: user.id,
    },
  })

  if (loading && (!data || Object.keys(data).length <= 0)) {
    return <Loading />
  }

  if (!data || !data.user) return null

  const businesses = data.user.businesses
  return <UserBusinessList businesses={businesses} />
}

interface Props {
  businesses: Array<GetUserBusinessList_user_businesses>
}

function UserBusinessList({ businesses = [] }: Props) {
  return (
    <Page.Content
      title="My Businesses"
      // subTitle={
      //   <Button
      //     outline
      //     RootComponent="a"
      //     href={`//app.marketzipper.com/signup`}
      //     color="secondary"
      //     icon="plus"
      //   >
      //     New Business
      //   </Button>
      // }
    >
      {businesses.length < 1 ? (
        <Alert type="warning" icon="alert-triangle" className="mb-0">
          No Businesses found
        </Alert>
      ) : (
        <Grid.Row cards deck>
          {businesses.map((business) => (
            <Grid.Col key={business.id} width={12} md={6} xl={4}>
              {/* <pre>{JSON.stringify(business, null, 2)}</pre> */}
              <Card>
                <List.Group isCardBody className="flex-grow-1">
                  <List.GroupItem
                    RootComponent={Link}
                    action
                    to={`/businesses/${business.id}`}
                    className="d-flex flex-grow-1 align-items-center"
                  >
                    <Media className="d-flex flex-grow-1">
                      <Media.Object className="mr-3">
                        <img
                          width={64}
                          src={
                            business.logo !== null
                              ? business.logo
                              : '/images/placeholder-company.png'
                          }
                          className="rounded"
                        />
                      </Media.Object>
                      <Media.Body>
                        <h4 className="mb-1">{business.name}</h4>
                        <address className="mb-2 text-muted small">
                          {business.streetAddress}
                          {business.city && (
                            <React.Fragment>
                              <br />
                              {business.city}
                              {business.state && (
                                <React.Fragment>
                                  , {business.state}
                                </React.Fragment>
                              )}{' '}
                              {business.postalCode}
                            </React.Fragment>
                          )}
                        </address>
                      </Media.Body>
                    </Media>
                    <Icon name="chevron-right" />
                  </List.GroupItem>

                  {(business.phoneNumber || business.webAddress) && (
                    <List.GroupItem RootComponent="li">
                      <Grid.Row>
                        {business.phoneNumber && (
                          <Grid.Col>
                            <Text className="text-body small">
                              {business.phoneNumber}
                            </Text>
                          </Grid.Col>
                        )}
                        {business.webAddress && (
                          <Grid.Col>
                            <Text className="text-body small">
                              {business.webAddress}
                            </Text>
                          </Grid.Col>
                        )}
                      </Grid.Row>
                    </List.GroupItem>
                  )}

                  <List.GroupItem>
                    <Grid.Row>
                      <Grid.Col>
                        <Text muted>Active Campaigns</Text>
                        <Text>
                          {
                            business.campaigns.filter((x) => x.active === true)
                              .length
                          }
                        </Text>
                      </Grid.Col>
                      <Grid.Col>
                        <Text muted>Total Daily Budget</Text>
                        <Text>
                          $
                          {business.campaigns
                            .filter((x) => x.active === true)
                            .reduce((total, campaign) => {
                              return total + (campaign.budgetDaily || 0)
                            }, 0)}
                        </Text>
                      </Grid.Col>
                    </Grid.Row>
                  </List.GroupItem>
                </List.Group>
              </Card>
            </Grid.Col>
          ))}
        </Grid.Row>
      )}
    </Page.Content>
  )
}

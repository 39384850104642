import * as React from 'react'
import {
  Page,
  Grid,
  Button,
  Card,
  List,
  Media,
  Table,
  Text,
} from 'tabler-react'
import PaymentMethodList from './PaymentMethodList'
import { GetUserBusinessDetails_business } from '../../../types'
import ConnectionsList from './ConnectionsList'

interface Props {
  business: GetUserBusinessDetails_business
}

const BusinessDetails = (props: Props) => {
  const { business } = props
  return (
    <Page.Content
      title={business.name}
      subTitle={
        <Button.List className="d-none">
          <Button link>Payment Methods</Button>
          <Button link>Connections</Button>
          <Button link>Campaigns</Button>
        </Button.List>
      }
      options={
        <Media className="flex-grow-1">
          {/* <Media.Object>
            <img
              width={100}
              src={
                business.logo !== null
                  ? business.logo
                  : '/images/placeholder-company.png'
              }
              className="mr-2"
            />
          </Media.Object> */}
          <Media.Body>
            <Grid.Row>
              {business.streetAddress && (
                <Grid.Col>
                  <Text className="small font-weight-bold">Address</Text>
                  <address className="mb-2 text-muted">
                    {business.streetAddress}
                    {business.city && (
                      <React.Fragment>
                        <br />
                        {business.city}
                        {business.state && (
                          <React.Fragment>, {business.state}</React.Fragment>
                        )}{' '}
                        {business.postalCode}
                      </React.Fragment>
                    )}
                  </address>
                </Grid.Col>
              )}
              <Grid.Col>
                <Text className="small font-weight-bold">Contact</Text>
                {business.phoneNumber && (
                  <p className="mb-1 small">
                    <a
                      href={`tel:${business.phoneNumber}`}
                      className="text-muted"
                    >
                      {business.phoneNumber}
                    </a>
                  </p>
                )}
                {business.webAddress && (
                  <p className="mb-1 small">
                    <a
                      href={`${business.webAddress}`}
                      className="text-muted"
                      target="_blank"
                    >
                      {business.webAddress}
                    </a>
                  </p>
                )}
              </Grid.Col>
            </Grid.Row>
          </Media.Body>
        </Media>
      }
    >
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}

      <h2 className="card-title mb-3">Summary</h2>
      <Card>
        <List.Group isCardBody className="flex-grow-1">
          <List.GroupItem className="d-flex flex-grow-1 align-items-center">
            <Grid.Row className="flex-grow-1">
              <Grid.Col>
                <Text className="text-muted">Total Leads</Text>
                <Text className="display-5 font-weight-normal text-primary">
                  500
                </Text>
              </Grid.Col>
              <Grid.Col>
                <Text className="text-muted">Customers</Text>
                <Text className="display-5 font-weight-normal text-primary">
                  10
                </Text>
              </Grid.Col>
              <Grid.Col>
                <Text className="text-muted">Spent</Text>
                <Text className="display-5 font-weight-normal text-primary">
                  $1,500
                </Text>
              </Grid.Col>
            </Grid.Row>
          </List.GroupItem>
          <List.GroupItem className="d-flex flex-grow-1 align-items-center">
            <Grid.Row className="flex-grow-1">
              <Grid.Col>
                <Text className="text-muted">Zip Pages</Text>
                <Text className="display-5">10</Text>
              </Grid.Col>
              <Grid.Col>
                <Text className="text-muted">Impressions</Text>
                <Text className="display-5">25,000</Text>
              </Grid.Col>
              <Grid.Col>
                <Text className="text-muted">Clicks</Text>
                <Text className="display-5">500</Text>
              </Grid.Col>
            </Grid.Row>
          </List.GroupItem>
          <List.GroupItem className="d-flex flex-grow-1 align-items-center">
            <Grid.Row className="flex-grow-1">
              <Grid.Col>
                <Text className="text-muted">Campaigns</Text>
                <Text className="display-5">
                  10
                  {/* {
                        business.campaigns.filter((x) => x.active === true)
                          .length
                      } */}
                </Text>
              </Grid.Col>
              <Grid.Col>
                <Text className="text-muted">CPC</Text>
                <Text className="display-5">$0.25</Text>
              </Grid.Col>
              <Grid.Col>
                <Text className="text-muted">Daily Budget</Text>
                <Text className="display-5">
                  $500
                  {/*
                        $
                        {business.campaigns
                          .filter((x) => x.active === true)
                          .reduce((total, campaign) => {
                            return total + (campaign.budgetDaily || 0)
                          }, 0)}{' '}
                          */}
                </Text>
              </Grid.Col>
            </Grid.Row>
          </List.GroupItem>
        </List.Group>
      </Card>

      <h2 className="card-title mb-3">Campaigns</h2>
      <Card>
        <div className="table-responsive">
          <Table cards highlightRowOnHover striped>
            <Table.Header>
              <Table.Row>
                <Table.ColHeader className="text-nowrap">
                  Campaign Name
                </Table.ColHeader>
                <Table.ColHeader className="text-nowrap">
                  Zip Page
                </Table.ColHeader>
                <Table.ColHeader className="text-nowrap">
                  Channel
                </Table.ColHeader>
                <Table.ColHeader className="text-nowrap" alignContent="right">
                  Clicks
                </Table.ColHeader>
                <Table.ColHeader className="text-nowrap" alignContent="right">
                  Impressions
                </Table.ColHeader>
                <Table.ColHeader className="text-nowrap" alignContent="right">
                  Conversion
                </Table.ColHeader>
                <Table.ColHeader className="text-nowrap" alignContent="right">
                  Cost
                </Table.ColHeader>
                <Table.ColHeader className="text-nowrap" alignContent="right">
                  CPC
                </Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Array(6)
                .fill(null)
                .map((i) => (
                  <Table.Row key={Math.random()}>
                    <Table.Col>Campaing Name {i}</Table.Col>
                    <Table.Col>Zip Page Name</Table.Col>
                    <Table.Col>Google</Table.Col>
                    <Table.Col alignContent="right">3,000</Table.Col>
                    <Table.Col alignContent="right">3,000</Table.Col>
                    <Table.Col alignContent="right">250</Table.Col>
                    <Table.Col alignContent="right">$1,500</Table.Col>
                    <Table.Col alignContent="right">$1.50</Table.Col>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </div>
      </Card>

      <h2 className="card-title mb-3">Payment Methods</h2>
      <PaymentMethodList businessId={business.id} />

      <h2 className="card-title mb-3">Connections</h2>
      <ConnectionsList business={business} />
    </Page.Content>
  )
}

export default BusinessDetails

import * as React from 'react'
import { FormFeedback, Input } from 'reactstrap'
import { FieldProps } from 'formik'

const TextInput = ({
  field,
  form: { touched, errors },
  ...other
}: FieldProps) => {
  if (!field.name) {
    throw new Error('Field must have a name property')
  }

  const fieldName = field.name
  const invalid = Boolean(touched[fieldName] && errors[fieldName])
  return (
    <React.Fragment>
      <Input type="text" {...field} {...other} invalid={invalid} />
      {invalid && <FormFeedback>{errors[fieldName]}</FormFeedback>}
    </React.Fragment>
  )
}

export default TextInput

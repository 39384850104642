import React from 'react'
import { RouteComponentProps, Redirect } from 'react-router'
import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'
import { MutationFn } from 'react-apollo'
import { Loader, Page, Grid, Card } from 'tabler-react'
import { ConnectToGoogle, ConnectToGoogleVariables } from '../../../types'
import AuthErrorScreen from './AuthErrorScreen'
import { getRedirectUrl, getGoogleAuthParams } from '../../google-auth'

export { default as ConnectToGoogleSuccess } from './ConnectToGoogleSuccess'
export { default as ConnectToGoogleFailure } from './ConnectToGoogleFailure'
export { default as NoAdsAccountFound } from './NoAdsAccountFound'

interface Props {
  businessId: string
  authCode: string
  connectToGoogle: MutationFn<ConnectToGoogle, ConnectToGoogleVariables>
}

interface State {
  stage:
    | 'in-progress'
    | 'choose-account'
    | 'complete'
    | 'error'
    | 'errror-no-account'
}

class ConnectToGoogleUI extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { stage: 'in-progress' }
    this.processAuthCode()
  }

  private processAuthCode() {
    this.props
      .connectToGoogle({
        variables: {
          business: this.props.businessId,
          code: this.props.authCode,
          redirectUrl: getRedirectUrl(),
        },
      })
      .then((response) => {
        console.log('response=', response)
        if (!response || !response.data) {
          throw new Error('Request failed')
        }

        if (response.data && response.data.connectToGoogle.success === true) {
          this.setState({ stage: 'complete' })
        } else {
          const reason = response.data && response.data.connectToGoogle.reason
          this.setState({
            stage: reason === 'NOT_ADS_USER' ? 'errror-no-account' : 'error',
          })
        }
      })
  }

  render() {
    // console.log('ConnectToGoogle', this.props)
    const { stage } = this.state

    return (
      <Page.Content>
        <Page.Header>
          <Page.Title className="m-auto">
            Connecting your google account&hellip;
          </Page.Title>
        </Page.Header>
        <Grid.Row>
          <Grid.Col md={8} offsetMd={2} lg={6} offsetLg={3}>
            {stage === 'in-progress' && (
              <Card statusColor="blue">
                <Card.Body>
                  <p>
                    Please wait while we finish connecting your Google Ads
                    account.
                  </p>
                  <Loader className="mx-auto mt-3" />
                </Card.Body>
              </Card>
            )}

            {/* Redirects are to clear the google auth params from the url */}
            {stage === 'complete' && (
              <Redirect
                to={{
                  pathname: '/connect-to-google/complete',
                  search: `?businessId=${this.props.businessId}`,
                }}
              />
            )}

            {stage === 'error' && (
              <Redirect
                to={{
                  pathname: '/connect-to-google/failure',
                  search: `?businessId=${this.props.businessId}`,
                }}
              />
            )}

            {stage === 'errror-no-account' && (
              <Redirect
                to={{
                  pathname: '/connect-to-google/no-ads-account',
                  search: `?businessId=${this.props.businessId}`,
                }}
              />
            )}
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    )
  }
}

const CONNECT_TO_GOOGLE_MUTATION = gql`
  mutation ConnectToGoogle(
    $business: ID!
    $code: String!
    $redirectUrl: String!
  ) {
    connectToGoogle(
      business: $business
      token: $code
      redirectUrl: $redirectUrl
    ) {
      success
      reason
    }
  }
`

interface WrapperProps extends RouteComponentProps {}

const ConnectToGoogleWrapper = ({ location }: WrapperProps) => {
  const [connectToGoogle] = useMutation<
    ConnectToGoogle,
    ConnectToGoogleVariables
  >(CONNECT_TO_GOOGLE_MUTATION)

  try {
    const params = getGoogleAuthParams(location.search)
    return (
      <ConnectToGoogleUI
        businessId={params.businessId}
        authCode={params.authCode}
        connectToGoogle={connectToGoogle}
      />
    )
  } catch (err) {
    return <AuthErrorScreen error={err.message} />
  }
}

export { ConnectToGoogleWrapper as ConnectToGoogle }

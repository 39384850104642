
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import { List, Button, Badge } from 'tabler-react'

export default class TopicRow extends React.Component<any, any> {
  state = {
    isEditing: false,
    text: '',
  }

  enableEditing = (e?: React.SyntheticEvent<HTMLInputElement>) => {
    if (e) e.preventDefault()
    this.setState({ isEditing: true, text: this.props.topic.name })
  }

  cancelEditing = () => {
    this.setState({ isEditing: false, text: '' })
  }

  handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({ text: e.currentTarget.value })
  }

  handleKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // ENTER KEY
    if (e.charCode === 13) {
      this.handleSave()
    }
  }

  handleSave = () => {
    const { topic } = this.props
    const { text } = this.state

    if (topic.name !== text) {
      this.props.onUpdateTopic(topic.id, text)
    }

    this.setState({ isEditing: false, text: '' })
  }

  handleDelete = (e: React.SyntheticEvent<HTMLInputElement>) => {
    e.preventDefault()
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to remove this topic?')) {
      this.props.onDeleteTopic(this.props.topic.id)
    }
  }

  renderEditing() {
    const { topic } = this.props
    return (
      <List.GroupItem
        action
        key={topic.id}
        className="d-flex justify-content-between align-items-center"
      >
        <input
          autoFocus
          type="text"
          className="form-control form-control-sm"
          placeholder="ex: Hot Water Tank"
          value={this.state.text}
          onChange={this.handleChange}
          onKeyPress={this.handleKeypress}
          onBlur={this.handleSave}
          style={{ marginRight: 12 }}
        />

        <Button.List>
          <Button
            key="cancel-btn"
            outline
            color="secondary"
            size="sm"
            onClick={(e: any) => e.preventDefault()}
          >
            Cancel
          </Button>
        </Button.List>
      </List.GroupItem>
    )
  }

  renderDisplay() {
    const { industryId, topic } = this.props
    return (
      <List.GroupItem
        action
        key={topic.id}
        to={`/library/industries/${industryId}/topics/${topic.id}`}
        RootComponent={NavLink}
        className="d-flex justify-content-between align-items-center"
      >
        <span>
          {topic.name}{' '}
          <Badge color={topic.keywords.length > 0 ? 'primary' : 'danger'}>
            {topic.keywords.length}
          </Badge>
        </span>

        <Button.List className="text-nowrap">
          <Button
            outline
            color="secondary"
            size="sm"
            onClick={this.enableEditing}
          >
            Edit
          </Button>
          <Button
            outline
            color="danger"
            size="sm"
            icon="trash-2"
            onClick={this.handleDelete}
          />
        </Button.List>
      </List.GroupItem>
    )
  }

  render() {
    return this.state.isEditing ? this.renderEditing() : this.renderDisplay()
  }
}


import * as React from 'react'
import { Grid, Card, Button, Form } from 'tabler-react'

export default class AddTopicForm extends React.Component<any, any> {
  state = {
    text: '',
  }

  handleTextChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({ text: e.currentTarget.value })
  }

  handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()
    this.props.onSubmit(this.state.text)
    this.setState({ text: '' })
  }

  render() {
    return (
      <Card.Footer className="bg-light">
        <h6>Add a New Topic</h6>
        <Form onSubmit={this.handleSubmit}>
          <Grid.Row gutters="xs">
            <Grid.Col>
              <Form.Input
                placeholder="ex: Hot Water Tank"
                value={this.state.text}
                onChange={this.handleTextChange}
              />
            </Grid.Col>
            <Grid.Col auto>
              <Button color="primary" icon="plus">
                Add
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Form>
      </Card.Footer>
    )
  }
}

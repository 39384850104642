
import React from 'react'

export type UserRole = 'customer' | 'agent' | 'admin'

export type AuthenticatedUser = {
  authenticated: true
  id: string
  name: string
  email: string
  role: UserRole
}

export type UnknownUser = { authenticated: false }
export type UserInfo = UnknownUser | AuthenticatedUser
export type UserContextType = {
  user: UserInfo
  setUser: (user: UserInfo) => any
}

const UserContext = React.createContext<UserContextType>({
  user: {
    authenticated: false,
  },
  setUser: () => undefined,
})

export default UserContext
export const UserProvider = UserContext.Provider
export const UserConsumer = UserContext.Consumer

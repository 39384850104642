import React, { Component, SyntheticEvent } from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { Link } from 'react-router-dom'
import {
  Alert,
  Icon,
  Page,
  Card,
  Form,
  Progress,
  Table,
  Text,
} from 'tabler-react'
import Helmet from 'react-helmet'
import { Loading } from '../../components'
import {
  GetBusinessList,
  GetBusinessListVariables,
  GetBusinessList_businesses_edges_node,
} from '../../types'

const PAGE_SIZE = 12

interface Props {
  businesses: GetBusinessList_businesses_edges_node[]
  lastCursor: string
  fetchMore: Function
}

interface State {
  cursors: string[]
  filterName: string
}

class BusinessList extends Component<Props, State> {
  static defaultProps = {
    businesses: [],
  }

  state: State = {
    cursors: [],
    filterName: '',
  }

  handleFilter = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    this.setState({ filterName: value, cursors: [] })

    const variables: GetBusinessListVariables = {
      first: PAGE_SIZE,
      after: null,
    }
    // @ts-ignore
    if (value !== '') variables.filter = { name: value }

    this.props.fetchMore({
      variables,
      updateQuery(previousResult: any, { fetchMoreResult }: any) {
        return fetchMoreResult
      },
    })
  }

  handlePreviousPage = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    const { cursors } = this.state
    cursors.pop()

    const variables: Object = {
      first: PAGE_SIZE,
      after: cursors.length > 0 ? cursors[cursors.length - 1] : undefined,
    }

    if (this.state.filterName !== '') {
      // @ts-ignore
      variables.filter = { name: this.state.filterName }
    }

    this.setState({ cursors })
    this.props.fetchMore({
      variables,
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        return fetchMoreResult
      },
    })
  }

  handleNextPage = (e: SyntheticEvent<HTMLAnchorElement>) => {
    e.preventDefault()

    const variables: Object = {
      first: PAGE_SIZE,
      after: this.props.lastCursor,
    }

    if (this.state.filterName !== '') {
      // @ts-ignore
      variables.filter = { name: this.state.filterName }
    }

    this.props.fetchMore({
      variables,
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        return fetchMoreResult
      },
    })

    this.setState({ cursors: this.state.cursors.concat(this.props.lastCursor) })
  }

  render() {
    const { businesses, lastCursor } = this.props

    const disablePrevious = this.state.cursors.length < 1
    const disableNext = lastCursor === '' || businesses.length < PAGE_SIZE

    return (
      <Page.Content
        title="Businesses"
        subTitle={
          <Form>
            <Form.Group className="mb-0">
              <Form.Input
                id="businessNameFilter"
                icon="search"
                position="append"
                placeholder="Find a Business..."
                onChange={this.handleFilter}
              />
            </Form.Group>
          </Form>
        }
      >
        <Helmet title="Businesses" />
        <Card>
          {businesses.length > 0 ? (
            <div className="table-responsive">
              <Table striped highlightRowOnHover cards>
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader className="w-1">ID</Table.ColHeader>
                    <Table.ColHeader className="text-nowrap">
                      Business Name
                    </Table.ColHeader>
                    <Table.ColHeader>Status</Table.ColHeader>
                    <Table.ColHeader>Subscription</Table.ColHeader>
                    <Table.ColHeader className="text-nowrap">
                      Payment Method
                    </Table.ColHeader>
                    <Table.ColHeader>Campaigns</Table.ColHeader>
                    <Table.ColHeader alignContent="right">
                      Impressions
                    </Table.ColHeader>
                    <Table.ColHeader
                      className="text-nowrap"
                      alignContent="right"
                    >
                      Total Leads
                    </Table.ColHeader>
                    <Table.ColHeader alignContent="right">
                      Revenue
                    </Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {businesses.map((business) => (
                    <Table.Row key={business.id}>
                      <Table.Col className="text-muted">
                        {business.id}
                      </Table.Col>
                      <Table.Col className="text-nowrap">
                        <Link to={`/businesses/${business.id}`}>
                          {business.name}
                        </Link>
                      </Table.Col>
                      <Table.Col className="text-nowrap">
                        <div className="d-flex justify-content-between">
                          <Text.Small muted>Onboarding</Text.Small>
                          <Text.Small muted>42%</Text.Small>
                        </div>
                        <Progress size="xs">
                          <Progress.Bar width={42} color="warning" />
                        </Progress>
                        {/* <Text className="text-success">
                          <span style={{ fontSize: 10 }}>
                            <Icon prefix="fa" name="circle" className="mr-1" />
                          </span>
                          Onboarding
                        </Text> */}
                      </Table.Col>
                      <Table.Col>Free</Table.Col>
                      <Table.Col>
                        <Icon payment name="visa" />
                      </Table.Col>
                      <Table.Col>
                        15
                        <Text.Small muted className="ml-2 text-nowrap">
                          (4 paused)
                        </Text.Small>
                      </Table.Col>
                      <Table.Col alignContent="right">1,500</Table.Col>
                      <Table.Col alignContent="right">500</Table.Col>
                      <Table.Col alignContent="right" className="text-nowrap">
                        $3,400
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          ) : (
            <Alert type="warning" icon="alert-triangle" className="mb-0">
              No Businesses found
            </Alert>
          )}
        </Card>

        {businesses.length > 0 && (
          <Pagination>
            <PaginationItem disabled={disablePrevious}>
              <PaginationLink
                href="#previous"
                onClick={this.handlePreviousPage}
              >
                Previous
              </PaginationLink>
            </PaginationItem>
            <PaginationItem disabled={disableNext}>
              <PaginationLink href="#next" onClick={this.handleNextPage}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        )}
      </Page.Content>
    )
  }
}

const LOAD_BUSINESS_LIST = gql`
  query GetBusinessList(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: BusinessFilter
  ) {
    businesses(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) @connection(key: "businesses") {
      edges {
        node {
          id
          name
          campaigns {
            id
            budgetDaily
            active
          }
          createdAt
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export default function BusinessListWrapper() {
  const { data, loading, fetchMore } = useQuery<
    GetBusinessList,
    GetBusinessListVariables
  >(LOAD_BUSINESS_LIST, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: PAGE_SIZE,
    },
  })

  if (loading && (!data || Object.keys(data).length <= 0)) {
    return <Loading />
  }

  if (!data || !data.businesses) throw new Error('Unable to load data')

  const businesses = data.businesses.edges.map((edge) => edge.node)

  const length = businesses.length ? data.businesses.edges.length - 1 : 0
  const lastCursor = businesses.length
    ? data.businesses.edges[length].cursor
    : ''

  return (
    <BusinessList
      lastCursor={lastCursor}
      businesses={businesses}
      fetchMore={fetchMore}
    />
  )
}

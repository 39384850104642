import React from 'react'
import moment from 'moment'
import { Button, Card, Icon, List, Text } from 'tabler-react'
import {
  UserDashboardData_businesses_edges_node_paymentMethods,
  UserDashboardData_viewer,
  UserDashboardData_businesses_edges_node_accounts,
} from '../../../types'
import { CardBrandIcon } from '../../../components'
import { getGawOauthUrl } from '../../google-auth'

interface Props {
  business: {
    id: string
    accounts: UserDashboardData_businesses_edges_node_accounts
    paymentMethods: UserDashboardData_businesses_edges_node_paymentMethods[]
  }
  viewer: UserDashboardData_viewer
}

export default function SetupAndConnectionsCard({ business, viewer }: Props) {
  const defaultPaymentMethod = business.paymentMethods.find(
    (x) => x.isDefault === true
  )
  const gawIsConnected = business.accounts.gaw.isConnected
  return (
    <Card title="Setup &amp; Connections">
      <List.Group isCardBody>
        <List.GroupItem
          RootComponent="li"
          className="d-flex align-items-center"
        >
          <Text className="flex-grow-1">
            <span
              className={`mr-3 ${
                defaultPaymentMethod ? 'text-success' : 'text-danger'
              }`}
            >
              <Icon
                name={defaultPaymentMethod ? 'check-square' : 'alert-triangle'}
              />
            </span>
            Payment Method
          </Text>

          {defaultPaymentMethod ? (
            <Button
              link
              RootComponent="a"
              href={`businesses/${business.id}/payment-methods`}
              className="p-0 text-body"
            >
              <CardBrandIcon brand={defaultPaymentMethod.brand} />
              <span className="align-top ml-2">
                **** {defaultPaymentMethod.last4}
              </span>
            </Button>
          ) : (
            <Button
              RootComponent="a"
              href={`businesses/${business.id}/payment-methods/new`}
              className="btn btn-sm btn-primary"
            >
              Add a Card
            </Button>
          )}
        </List.GroupItem>

        <List.GroupItem RootComponent="li" className="d-flex">
          <Text className="flex-grow-1">
            <span
              className={`mr-3 ${
                gawIsConnected ? 'text-success' : 'text-danger'
              }`}
            >
              <Icon name={gawIsConnected ? 'check-square' : 'alert-triangle'} />
            </span>
            Google Ads
          </Text>

          {gawIsConnected ? (
            <Text className="text-success">Connected</Text>
          ) : (
            <Button
              RootComponent="a"
              href={getGawOauthUrl(business.id)}
              className="btn btn-sm btn-primary"
            >
              Connect
            </Button>
          )}
        </List.GroupItem>
        <List.GroupItem RootComponent="li" className="d-flex">
          <Text className="flex-grow-1">
            {viewer.lastMobileLoginAt == null ? (
              <span className="mr-3 text-danger">
                <Icon name="alert-triangle" />
              </span>
            ) : (
              <span className="mr-3 text-success">
                <Icon name="check-square" />
              </span>
            )}
            Mobile App Last Login
          </Text>
          <Text.Small muted>
            {viewer.lastMobileLoginAt == null
              ? 'Never'
              : moment(viewer.lastMobileLoginAt).format('MMM D, YYYY, h:mm a')}
          </Text.Small>
        </List.GroupItem>
        <List.GroupItem RootComponent="li" className="d-flex">
          <Text className="flex-grow-1">
            <span className="mr-3 text-success">
              <Icon name="check-square" />
            </span>
            Subscription Plan
          </Text>
          <Button
            RootComponent="a"
            href={``}
            className="btn btn-sm btn-outline-success"
          >
            Free Plan
          </Button>
        </List.GroupItem>
      </List.Group>
    </Card>
  )
}

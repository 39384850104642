import React from 'react'
import { Card, Grid, Progress, List, Text } from 'tabler-react'
import { UserDashboardData_businesses_edges_node_onboardingStatus } from '../../../types'

interface Props {
  status: UserDashboardData_businesses_edges_node_onboardingStatus
}

export default function ActivityStatsCard({ status }: Props) {
  const body = status.complete ? (
    <ActivityStats />
  ) : (
    <OnboardingInfo progress={status.wizardStatus.overallProgress} />
  )
  return <Card>{body}</Card>
}

function ActivityStats() {
  return (
    <List.Group isCardBody className="flex-grow-1">
      <List.GroupItem className="d-flex flex-grow-1 align-items-center">
        <Grid.Row className="flex-grow-1">
          <Grid.Col>
            <Text className="text-muted">Campaigns</Text>
            <Text className="display-5">10</Text>
          </Grid.Col>
          <Grid.Col>
            <Text className="text-muted">Leads</Text>
            <Text className="display-5">500</Text>
          </Grid.Col>
          <Grid.Col>
            <Text className="text-muted">Spent</Text>
            <Text className="display-5">$1,500</Text>
          </Grid.Col>
        </Grid.Row>
      </List.GroupItem>
      <List.GroupItem className="d-flex flex-grow-1 align-items-center">
        <Grid.Row className="flex-grow-1">
          <Grid.Col>
            <Text className="text-muted">Zip Pages</Text>
            <Text className="display-5">10</Text>
          </Grid.Col>
          <Grid.Col>
            <Text className="text-muted">Impressions</Text>
            <Text className="display-5">25,000</Text>
          </Grid.Col>
          <Grid.Col>
            <Text className="text-muted">Clicks</Text>
            <Text className="display-5">500</Text>
          </Grid.Col>
        </Grid.Row>
      </List.GroupItem>
      <List.GroupItem className="d-flex flex-grow-1 align-items-center">
        <Grid.Row className="flex-grow-1">
          <Grid.Col>
            <Text className="text-muted">Conversion</Text>
            <Text className="display-5">10</Text>
          </Grid.Col>
          <Grid.Col>
            <Text className="text-muted">CPC</Text>
            <Text className="display-5">$0.25</Text>
          </Grid.Col>
          <Grid.Col>
            <Text className="text-muted">Daily Budget</Text>
            <Text className="display-5">$500</Text>
          </Grid.Col>
        </Grid.Row>
      </List.GroupItem>
    </List.Group>
  )
}

interface OnboardingInfoProps {
  progress: number
}

function OnboardingInfo({ progress }: OnboardingInfoProps) {
  return (
    <Card.Body>
      <div className="d-flex justify-content-between">
        <Text.Small muted>Onboarding</Text.Small>
        <strong>{progress}%</strong>
      </div>

      <Progress size="xs">
        <Progress.Bar width={progress} color="warning" />
      </Progress>

      <div className="m-auto p-5 text-center">
        <Text.Small muted>
          Your stats will appear here after you've created and publish a
          campaign using the Mobile App.
        </Text.Small>
      </div>
    </Card.Body>
  )
}


import * as React from 'react'
import { Query, Mutation, QueryResult, MutationFn } from 'react-apollo'
import gql from 'graphql-tag'
import { withFormik } from 'formik'
import { Alert, FormCard, FormTextInput } from 'tabler-react'
import * as yup from 'yup'
import { StandaloneFormPage } from '../../components'
import {
  ResetPassword,
  ResetPasswordVariables,
  VerifyResetToken,
} from '../../types'

const VERIFY_TOKEN_QUERY = gql`
  query VerifyResetToken($token: String!) {
    verifyPasswordResetToken(token: $token)
  }
`

const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`

const ResetPasswordFormContent = ({
  token,
  handleBlur,
  handleChange,
  handleSubmit,
  values,
  errors,
  touched,
  status,
}: any) => (
  <StandaloneFormPage>
    {status && <Alert type={status.type}>{status.message}</Alert>}

    {(!status || status.type !== 'success') && (
      <FormCard
        title="Reset Your Password"
        onSubmit={handleSubmit}
        buttonText="Reset Password"
      >
        <FormTextInput
          name="password"
          type="password"
          label="New Password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.password}
          error={touched.password && errors.password}
        />

        <FormTextInput
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.confirmPassword}
          error={touched.confirmPassword && errors.confirmPassword}
        />
      </FormCard>
    )}
  </StandaloneFormPage>
)

const schema = yup.object().shape({
  password: yup
    .string()
    .required()
    .label('Password'),
  confirmPassword: yup
    .string()
    .required()
    .label('Confirm Password'),
})

const ResetPasswordForm = withFormik({
  mapPropsToValues() {
    return { password: '', confirmPassword: '' }
  },
  validationSchema: schema,
  async handleSubmit(
    values: any,
    { props, setFieldError, setSubmitting, setStatus, resetForm }: any
  ) {
    if (values.password !== values.confirmPassword) {
      setFieldError('password', 'Please make sure both passwords match')
      setSubmitting(false)
      return
    }

    try {
      await props.resetPassword({
        variables: { token: props.token, password: values.password },
      })

      resetForm()
      setStatus({ type: 'success', message: 'Your password has been reset' })
      setSubmitting(false)
    } catch (err) {
      console.log('Unable to reset password', err)
      resetForm()
      setStatus({
        type: 'danger',
        message: 'Unable to reset your password at this time.',
      })
      setSubmitting(false)
    }
  },
})(ResetPasswordFormContent)

const ResetPasswordWrapper = ({ history, match }: any) => {
  const token = match.params.token
  return (
    <Mutation mutation={RESET_PASSWORD_MUTATION}>
      {(resetPassword: MutationFn<ResetPassword, ResetPasswordVariables>) => (
        <Query query={VERIFY_TOKEN_QUERY} variables={{ token }}>
          {({ data, loading }: QueryResult<VerifyResetToken>) => {
            if (loading) return <div>Loading...</div>
            if (!data || !data.verifyPasswordResetToken) {
              setTimeout(() => history.replace('/login'), 100)
              return null
            }

            return (
              // @ts-ignore
              <ResetPasswordForm resetPassword={resetPassword} token={token} />
            )
          }}
        </Query>
      )}
    </Mutation>
  )
}

export default ResetPasswordWrapper

import * as React from 'react'
import { Card, Grid, Table, Text } from 'tabler-react'
import SendNotification from './SendNotification'
import { BusinessDetailsT, ZipPageList } from './BusinessWrapper'

type Props = {
  business: BusinessDetailsT
  zipPages: ZipPageList
}

const DetailsPage = (props: Props) => {
  const { business, zipPages } = props
  return (
    <React.Fragment>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}

      {business.webAddress || business.phoneNumber ? (
        <Card title="Details">
          <Card.Body>
            <Grid.Row>
              {business.webAddress && (
                <Grid.Col>
                  <Text muted className="text-uppercase">
                    Web
                  </Text>
                  <a
                    href={business.webAddress}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {business.webAddress}
                  </a>
                </Grid.Col>
              )}
              {business.phoneNumber && (
                <Grid.Col>
                  <Text muted className="text-uppercase">
                    Phone
                  </Text>
                  <a
                    href={business.phoneNumber}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {business.phoneNumber}
                  </a>
                </Grid.Col>
              )}
            </Grid.Row>
          </Card.Body>
        </Card>
      ) : null}

      <Card title={`Zip Pages (${zipPages.length})`}>
        <Table cards>
          <Table.Header>
            <Table.Row>
              <Table.ColHeader>Page Name</Table.ColHeader>
              <Table.ColHeader>URL</Table.ColHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {zipPages.map((zipPage: any) => (
              <Table.Row key={zipPage.id}>
                <Table.Col>{zipPage.name}</Table.Col>
                <Table.Col>
                  <a
                    href={zipPage.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {zipPage.url}
                  </a>
                </Table.Col>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Card>
      <Card>
        <Card.Body>
          <SendNotification businessId={business.id} />
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

export default DetailsPage

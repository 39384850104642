import * as React from 'react'
import { Link } from 'react-router-dom'
import { Card, Dropdown, Icon, Table, Text } from 'tabler-react'
import { GetBusinessCampaigns } from '../../types'

type Props = {
  businessId: string
  campaigns: any //$PropertyType<GetBusinessCampaigns, 'campaigns'>,
}

const BusinessCampaigns = ({ campaigns, businessId }: Props) => (
  <Card title="Campaigns">
    {/* <pre>{JSON.stringify(campaigns, null, 2)}</pre> */}

    <div className="table-responsive">
      <Table cards>
        <Table.Header>
          <Table.Row>
            <Table.ColHeader>Status</Table.ColHeader>
            <Table.ColHeader>Campaign Name</Table.ColHeader>
            <Table.ColHeader alignContent="right">Impressions</Table.ColHeader>
            <Table.ColHeader alignContent="right">Clicks</Table.ColHeader>
            <Table.ColHeader alignContent="right">Budget</Table.ColHeader>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {campaigns.map((campaign: any) => (
            <Table.Row key={campaign.id}>
              <Table.Col>
                <Dropdown
                  toggle
                  arrow
                  type="link"
                  icon={campaign.active ? null : 'pause-circle'}
                  triggerContent={campaign.active ? 'Running' : 'Paused'}
                  triggerClassName={
                    campaign.active ? 'text-success' : 'text-muted'
                  }
                  itemsObject={[
                    { value: 'Pause' },
                    { isDivider: true },
                    { value: 'Enable' },
                  ]}
                />
                {/* <span className="text-muted text-nowrap">
                  <Icon name="pause-circle" /> Paused
                </span> */}
              </Table.Col>
              <Table.Col>
                <Text>
                  <Link
                    to={`/businesses/${businessId}/campaigns/${campaign.id}`}
                  >
                    {campaign.name}
                  </Link>
                </Text>
                <Text.Small muted>
                  {campaign.topics == '' ? (
                    'No Topics'
                  ) : (
                    <React.Fragment>
                      <strong className="text-body">Topics: </strong>
                      {campaign.topics
                        .map((topic: any) => topic.name)
                        .join(', ')}
                    </React.Fragment>
                  )}
                </Text.Small>
              </Table.Col>
              <Table.Col alignContent="right">3,000</Table.Col>
              <Table.Col alignContent="right">250</Table.Col>
              <Table.Col alignContent="right">$35</Table.Col>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  </Card>
)

export default BusinessCampaigns

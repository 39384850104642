import * as React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import {
  CardElement,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements'
import { Form, FormGroup, Label } from 'reactstrap'
import { withFormik, Field, FormikProps } from 'formik'
import { Button, Page, Card, Grid } from 'tabler-react'
import { TextInput } from '../../../components'

interface FormValues {
  name: string
  address: string
  stripe: string
}

interface FormProps extends RouteComponentProps<{ business: string }> {
  addPaymentMethod: Function
  stripe?: ReactStripeElements.StripeProps
}

type Props = FormProps & FormikProps<FormValues>

class InnerPaymentForm extends React.Component<Props> {
  render() {
    const { errors, isSubmitting, handleSubmit } = this.props
    return (
      <Page.Content>
        <Page.Header>
          <Page.Title className="m-auto">Add a New Payment Method</Page.Title>
        </Page.Header>
        <Grid.Row>
          <Grid.Col md={8} offsetMd={2} lg={6} offsetLg={3}>
            <Form onSubmit={handleSubmit}>
              <Card statusColor={!errors.stripe ? 'blue' : 'red'}>
                <Card.Body>
                  <FormGroup>
                    <Label>Card Information {errors.stripe}</Label>
                    <CardElement
                      onReady={(el) => el.focus()}
                      style={{
                        base: {
                          fontSize: '18px',
                          '::placeholder': {
                            color: '#adb5bc',
                          },
                        },
                      }}
                    />
                    {errors.stripe && (
                      <div
                        className="invalid-feedback"
                        style={{ display: 'block' }}
                      >
                        {errors.stripe}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Card Holder Name</Label>
                    <Field
                      name="name"
                      component={TextInput}
                      placeholder="e.g. John Doe"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Address</Label>
                    <Field
                      name="address"
                      component={TextInput}
                      placeholder="e.g. 345 Main St. Buffalo, NY 12345"
                    />
                  </FormGroup>
                </Card.Body>
                <Card.Footer>
                  <Button.List className="d-flex justify-content-between">
                    <Link
                      to={`/businesses/${this.props.match.params.business}`}
                      className="btn btn-outline-secondary"
                    >
                      <i className="fe fe-arrow-left" /> Cancel
                    </Link>
                    <Button
                      type="submit"
                      color="primary"
                      icon="check"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? 'Please wait...'
                        : 'Submit Payment Method'}
                    </Button>
                  </Button.List>
                </Card.Footer>
              </Card>
            </Form>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    )
  }
}

export default injectStripe(
  withFormik<FormProps, FormValues>({
    mapPropsToValues(props) {
      return {
        name: '',
        address: '',
        stripe: '',
      }
    },
    validate(values) {
      const errors: any = {}
      if (!values.name) {
        errors.name = 'Please provide your first & last name'
      }
      if (!values.address) {
        errors.address = 'Please provide your street address'
      }
      return errors
    },
    async handleSubmit(values, { props, setSubmitting, setErrors }) {
      const { stripe } = props

      const response = await stripe!.createSource({
        type: 'card',
        owner: {
          name: values.name, // USER FILL?
          address: {
            line1: values.address,
          },
          email: 'ecoleman@360psg.com', // SHOULD BE USER FILLED
        },
      })

      console.log('stripe response', response)

      if (response.error) {
        setErrors({ stripe: response.error.message })
        setSubmitting(false)
        return
      }

      const { source } = response

      if (!source || !source.card) {
        throw new Error('Unable to create card')
      }

      try {
        await props.addPaymentMethod({
          variables: {
            input: {
              business: props.match.params.business,
              source: source.id,
              brand: source.card.brand,
              last4: source.card.last4,
              expMonth: source.card.exp_month,
              expYear: source.card.exp_year,
            },
          },
        })
      } catch (err) {
        setErrors({ stripe: getPaymentErrorMessage(err) })
        setSubmitting(false)
        return
      }

      props.history.push(`/businesses/${props.match.params.business}`)
    },
  })(InnerPaymentForm)
)

function getPaymentErrorMessage(err: Error): string {
  if ('graphQLErrors' in err) {
    return (err as any).graphQLErrors.shift().message
  }
  return 'Unable to process your card information at this time'
}


import * as React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { match } from 'react-router-dom'
import { Loading } from '../../components'
import { GetBusinessCalls } from '../../types'
import CallsList from './CallsList'

const GET_BUSINESS_CALLS = gql`
  query GetBusinessCalls($id: ID!) {
    calls(business: $id) {
      id
      duration
      callerId
      fromNumber
      createdAt
    }
  }
`

// { businessId: string; match: Match }
interface Props {
  businessId: string
  match: any
}

export default (props: Props) => {
  console.log('root props', props)
  return (
    <Query query={GET_BUSINESS_CALLS} variables={{ id: props.match.params.id }}>
      {({ data, loading }: QueryResult<GetBusinessCalls>) => {
        if (loading && (!data || !data.calls)) return <Loading />

        if (!data || !data.calls)
          throw new Error('Unable to load business calls')

        return <CallsList businessId={props.businessId} calls={data.calls} />
      }}
    </Query>
  )
}

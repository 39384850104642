import * as React from 'react'
import logo from '../assets/logo-horizontal-primary.png'
import { fullVersion } from '../version'

type Props = {
  children: React.ReactNode
}

function StandaloneFormPage(props: Props) {
  return (
    <div className="page">
      <div className="page-single">
        <div className="container">
          <div className="row">
            <div className="col col-login mx-auto">
              <div className="text-center mb-6">
                <img src={logo} className="h-6" alt="" />
              </div>

              {props.children}
            </div>
          </div>
          <div className="text-muted mt-6 text-center">
            <small>{fullVersion}</small>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StandaloneFormPage

import * as React from 'react'
import { Route, NavLink, Redirect, Switch } from 'react-router-dom'
import { SiteWrapper, NavItem } from '../components'
import Dashboard from '../pages/Dashboard'
import BusinessList from '../pages/BusinessList'
import BusinessWrapper from '../pages/BusinessDetails/BusinessWrapper'
import IncomingMailPage from '../pages/IncomingMail/IncomingMailPage'
import IncomingMailDetailsPage from '../pages/IncomingMail/IncomingMailDetailsPage'
import LibraryWrapper from '../pages/Library/LibraryWrapper'
import Logout from '../pages/Logout'

const navBarItems: Array<NavItem> = [
  {
    value: 'Dashboard',
    to: '/dashboard',
    icon: 'home',
    LinkComponent: NavLink,
  },
  {
    value: 'Businesses',
    to: '/businesses',
    icon: 'briefcase',
    LinkComponent: NavLink,
  },
  {
    value: 'Library',
    to: '/library',
    icon: 'book',
    LinkComponent: NavLink,
  },
  {
    value: 'Incoming Mail',
    to: '/incoming-mail',
    icon: 'mail',
    LinkComponent: NavLink,
  },
]

const AgentApp = () => (
  <SiteWrapper navItems={navBarItems}>
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <Route path="/dashboard" exact component={Dashboard} />

      <Route path="/businesses/:id" component={BusinessWrapper} />
      <Route exact path="/businesses" component={BusinessList} />

      <Route path="/library" component={LibraryWrapper} />

      <Route exact path="/incoming-mail" component={IncomingMailPage} />
      <Route
        exact
        path="/incoming-mail/:id"
        component={IncomingMailDetailsPage}
      />

      <Route exact path="/logout" component={Logout} />
      <Redirect from="/signup" to="/dashboard" />
      <Redirect from="/login" to="/dashboard" />
    </Switch>
  </SiteWrapper>
)

export default AgentApp

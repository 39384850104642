
import * as React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import SignUp from '../pages/SignUp'
import SignUpComplete from '../pages/SignUpComplete'
import Login from '../pages/Login'
import ResetPassword from '../pages/ResetPassword'

const LoginApplication = () => (
  <Switch>
    {/* <Redirect exact from="/" to="/dashboard" /> */}
    <Route path="/signup" exact component={SignUp} />
    <Route path="/signup/complete" exact component={SignUpComplete} />
    <Route path="/reset-password/:token" exact component={ResetPassword} />
    <Route path="/login" component={Login} />
    <Redirect to="/login" />
  </Switch>
)

export default LoginApplication

import React from 'react'
import { Card, Grid, Text } from 'tabler-react'
import { GetUserBusinessDetails_business } from '../../../types'
import { getGawOauthUrl } from '../../google-auth'

interface Props {
  business: GetUserBusinessDetails_business
}

export default function ConnectionsList({ business }: Props) {
  return (
    <Grid.Row>
      <Grid.Col xsAuto>
        <a
          href={getGawOauthUrl(business.id)}
          className="btn btn-lg btn-outline-primary d-flex align-items-center justify-content-center"
          style={{
            flexGrow: 1,
            marginBottom: '1.5rem',
            borderStyle: 'dashed',
            minHeight: 63,
          }}
        >
          <i className="fe fe-plus" /> Connect to Google Ads
        </a>
      </Grid.Col>

      {business.accounts.gaw.isConnected && (
        <Grid.Col key="gaw">
          <Card>
            <Card.Body>
              <h4 className="mb-0">Google Ads</h4>
              <Text>
                <span className="text-muted">Account ID: </span>
                {business.accounts.gaw.id}
              </Text>
            </Card.Body>
          </Card>
        </Grid.Col>
      )}
    </Grid.Row>
  )
}

import ApolloClient from 'apollo-boost'

const endpoint = '/api/graphql'
const client = new ApolloClient({
  uri: endpoint,
  request: async (operation) => {
    const token = localStorage.getItem('mz/auth')

    if (token == null || token.length <= 0) {
      return
      // throw new Error('Unable to find authentication token')
    }

    operation.setContext({
      headers: {
        authorization: `bearer ${token}`,
      },
    })
  },
})

export default client

import * as React from 'react'
import { NavLink, Switch, Route } from 'react-router-dom'
import { Badge, Page, Container, Grid, Icon, List } from 'tabler-react'
import Helmet from 'react-helmet'
import DetailsPage from './DetailsPage'
import CallsPage from './CallsPage'
import CallDetailsPage from './CallDetailsPage'
import CampaignListPage from './CampaignListPage'
import CampaignDetailsPage from './CampaignDetailsPage'

type BusinessDetailsT = any //$PropertyType<GetBusinessDetails, 'business'>
type ZipPageList = any // $PropertyType<GetBusinessDetails, 'zipPages'>
type Props = {
  business: BusinessDetailsT
  zipPages: ZipPageList
}

const ExactNavLink = (props: any) => <NavLink exact {...props} />

const BusinessMain = (props: Props) => {
  const { business, zipPages } = props

  const routeProps = {
    businessId: business.id,
    business,
    zipPages,
  }

  return (
    <Page.Content title={business.name}>
      <Helmet title={business.name} />
      <Grid.Row>
        <Grid.Col md={3}>
          <List.Group className="mb-5">
            <List.GroupItem
              className="d-flex align-items-center"
              to={`/businesses/${business.id}`}
              icon="home"
              RootComponent={ExactNavLink}
            >
              Details
            </List.GroupItem>
            <List.GroupItem
              className="d-flex align-items-center"
              to={`/businesses/${business.id}/campaigns`}
              RootComponent={NavLink}
            >
              <span className="mr-3 icon">
                <Icon prefix="fa" name="bullhorn" />
              </span>
              Campaigns
            </List.GroupItem>
            <List.GroupItem
              className="d-flex align-items-center"
              to={`/businesses/${business.id}/calls`}
              icon="phone"
              RootComponent={NavLink}
            >
              Calls <Badge className="ml-auto">2</Badge>
            </List.GroupItem>
          </List.Group>
        </Grid.Col>
        <Grid.Col md={9}>
          <Switch>
            <Route
              path="/businesses/:id/calls"
              exact
              render={(inner: any) => <CallsPage {...inner} {...routeProps} />}
            />
            <Route
              path="/businesses/:id/calls/:call"
              exact
              render={(inner: any) => (
                <CallDetailsPage {...inner} {...routeProps} />
              )}
            />
            <Route
              path="/businesses/:id/campaigns"
              exact
              render={(inner: any) => (
                <CampaignListPage {...inner} {...routeProps} />
              )}
            />
            <Route
              path="/businesses/:id/campaigns/:campaign"
              exact
              render={(inner: any) => (
                <CampaignDetailsPage {...inner} {...routeProps} />
              )}
            />

            <Route
              path=""
              exact
              render={(inner: any) => (
                <DetailsPage {...inner} {...routeProps} />
              )}
            />
          </Switch>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  )
}

export default BusinessMain

import React from 'react'
import { Page, Grid, Card } from 'tabler-react'

export default function ConnectToGoogleFailure() {
  return (
    <Page.Content>
      <Page.Header>
        <Page.Title className="m-auto">
          Unable to connect Google Ads account
        </Page.Title>
      </Page.Header>
      <Grid.Row>
        <Grid.Col md={8} offsetMd={2} lg={6} offsetLg={3}>
          <Card statusColor="red">
            <Card.Body>
              <p>
                We are unable to connect to your google account at this time.
                Please contaact support
              </p>
            </Card.Body>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  )
}

import * as React from 'react'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import {
  Page,
  Card,
  Grid,
  Icon,
  List,
  StatsCard,
  StampCard,
  Text,
} from 'tabler-react'
import { AdminDashboardData } from '../../types'
import { UserConsumer, Loading } from '../../components'

const DASHBOARD_QUERY = gql`
  query AdminDashboardData {
    businesses {
      edges {
        node {
          id
          name
          logo
          campaigns {
            id
            name
          }
        }
      }
    }
  }
`

// Admin stuff
const systemProcesses = [
  {
    name: 'mail',
    icon: 'mail',
    status: 'running',
  },
  {
    name: 'channel-sync',
    icon: 'repeat',
    status: 'error',
  },
  {
    name: 'visit-processor',
    icon: 'user',
    status: 'running',
  },
  {
    name: 'notifications',
    icon: 'bell',
    status: 'running',
  },
]

const Dashboard = () => (
  <UserConsumer>
    {({ user }) => {
      if (!user.authenticated)
        throw new Error('A user is required for this page')

      return (
        <Query query={DASHBOARD_QUERY}>
          {({ data, loading }: QueryResult<AdminDashboardData>) => {
            if (loading && (!data || !data.businesses)) return <Loading />

            if (!data || !data.businesses)
              throw new Error('Unable to load business list')

            {
              /* const businessList = data.businesses.edges.map((edge) => edge.node) */
            }

            return (
              <Page.Content title="Admin Dashboard">
                <Grid.Row>
                  <Grid.Col>
                    <StatsCard label="Businesses" movement={3} total="23" />
                  </Grid.Col>
                  <Grid.Col>
                    <StatsCard label="Zip Pages" movement={1} total="500" />
                  </Grid.Col>
                  <Grid.Col>
                    <StatsCard label="Campaigns" movement={5} total="300" />
                  </Grid.Col>
                  <Grid.Col>
                    <StatsCard label="Revenue" movement={3} total="$3,000" />
                  </Grid.Col>
                </Grid.Row>

                {/* <pre className="">{JSON.stringify(user, null, 2)}</pre> */}
                <Grid.Row>
                  <Grid.Col width={12} md={6} xl={3}>
                    <Link
                      to={`/users/`}
                      className="text-decoration-none text-dark"
                    >
                      <StampCard
                        icon="users"
                        header="23 Users"
                        footer="4 Waiting"
                      />
                    </Link>
                  </Grid.Col>

                  <Grid.Col width={12} md={6} xl={3}>
                    <Link
                      to={`/businesses/`}
                      className="text-decoration-none text-dark"
                    >
                      <StampCard
                        icon="briefcase"
                        color="primary"
                        header="23 Businesses"
                        footer="4 Onboarding"
                      />
                    </Link>
                  </Grid.Col>

                  <Grid.Col width={12} md={6} xl={3}>
                    <Link
                      to={`/campaigns/`}
                      className="text-decoration-none text-dark"
                    >
                      <StampCard
                        icon="briefcase"
                        color="primary"
                        header="230 Campaigns"
                        footer="4 Inactive"
                      />
                    </Link>
                  </Grid.Col>

                  <Grid.Col width={12} md={6} xl={3}>
                    <Link
                      to={`/library/`}
                      className="text-decoration-none text-dark"
                    >
                      <StampCard
                        icon="briefcase"
                        color="primary"
                        header="30 Industries"
                        footer="400 Topics"
                      />
                    </Link>
                  </Grid.Col>

                  <Grid.Col width={12} md={6} xl={3}>
                    <Link to={``} className="text-decoration-none text-dark">
                      <StampCard
                        icon="phone"
                        color="primary"
                        header="30 Phone Numbers"
                        footer="400 Voicemails"
                      />
                    </Link>
                  </Grid.Col>

                  <Grid.Col width={12} md={6} xl={3}>
                    <Link to={``} className="text-decoration-none text-dark">
                      <StampCard
                        icon="phone"
                        color="primary"
                        header="20 Coupons"
                        footer="400 Claimed"
                      />
                    </Link>
                  </Grid.Col>

                  <Grid.Col width={12} md={6} xl={3}>
                    <Link to={``} className="text-decoration-none text-dark">
                      <StampCard
                        icon="phone"
                        color="primary"
                        header="4 Subscription Plans"
                        footer="2 Inactive"
                      />
                    </Link>
                  </Grid.Col>

                  <Grid.Col width={12} md={6} xl={3}>
                    <Link to={``} className="text-decoration-none text-dark">
                      <StampCard
                        icon="phone"
                        color="primary"
                        header="40 Zip Pages"
                        footer="1 Template"
                      />
                    </Link>
                  </Grid.Col>

                  <Grid.Col width={12} md={6} xl={3}>
                    <Card className="p-3">
                      <Grid.Row>
                        <Grid.Col width={6}>
                          <Text className="mb-1 text-muted small">
                            for iPhone
                          </Text>
                          <a href="" target="_blank">
                            <img
                              src="/images/download-app-store.png"
                              alt="App Store"
                            />
                          </a>
                        </Grid.Col>
                        <Grid.Col width={6}>
                          <Text className="mb-1 text-muted small">
                            for Android
                          </Text>
                          <a href="" target="_blank">
                            <img
                              src="/images/download-play-store.png"
                              alt="Play Store"
                            />
                          </a>
                        </Grid.Col>
                      </Grid.Row>
                    </Card>
                  </Grid.Col>
                </Grid.Row>

                {/* <h2 className="card-title mb-3">
                  Businesses
                  <Link
                    to="/businesses"
                    className="btn btn-link text-decoration-none"
                  >
                    View All <Icon name="chevron-right" />
                  </Link>
                </h2>
                <Card>
                  <div className="table-responsive">
                    <Table cards highlightRowOnHover striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.ColHeader className="w-1">ID</Table.ColHeader>
                          <Table.ColHeader className="text-nowrap">
                            Business Name
                          </Table.ColHeader>
                          <Table.ColHeader>Status</Table.ColHeader>
                          <Table.ColHeader className="text-nowrap">
                            Payment Method
                          </Table.ColHeader>
                          <Table.ColHeader className="text-nowrap">
                            Subscription
                          </Table.ColHeader>
                          <Table.ColHeader
                            className="text-nowrap"
                            alignContent="right"
                          >
                            Phone Numbers
                          </Table.ColHeader>
                          <Table.ColHeader
                            className="text-nowrap"
                            alignContent="right"
                          >
                            Active Campaigns
                          </Table.ColHeader>
                          <Table.ColHeader
                            className="text-nowrap"
                            alignContent="right"
                          >
                            Impressions
                          </Table.ColHeader>
                          <Table.ColHeader
                            className="text-nowrap"
                            alignContent="right"
                          >
                            Total Leads
                          </Table.ColHeader>
                          <Table.ColHeader
                            className="text-nowrap"
                            alignContent="right"
                          >
                            Revenue
                          </Table.ColHeader>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {Array(5)
                          .fill(null)
                          .map(() => (
                            <Table.Row key={Math.random()}>
                              <Table.Col className="text-muted">
                                {(Math.random() * 10).toFixed(0)}
                              </Table.Col>
                              <Table.Col>
                                <Link to="">Business Name</Link>
                              </Table.Col>
                              <Table.Col>
                                <Text className="text-success">Active</Text>
                              </Table.Col>
                              <Table.Col>
                                <Icon payment name="visa" />
                              </Table.Col>
                              <Table.Col>Free</Table.Col>
                              <Table.Col alignContent="right">5</Table.Col>
                              <Table.Col alignContent="right">3</Table.Col>
                              <Table.Col alignContent="right">5,000</Table.Col>
                              <Table.Col alignContent="right">300</Table.Col>
                              <Table.Col alignContent="right">$1,500</Table.Col>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                  </div>
                </Card> */}

                <h2 className="card-title mb-3">System</h2>
                <Grid.Row>
                  {systemProcesses.map((k, i) => (
                    <Grid.Col key={i} xs={12}>
                      <Card
                        title={
                          <Text className="text-nowrap text-capitalize">
                            <Icon name={k.icon} className="text-muted mr-3" />
                            {k.name}
                          </Text>
                        }
                        options={
                          <Icon
                            name={
                              k.status === 'error' ? 'alert-triangle' : 'check'
                            }
                            className={
                              k.status === 'error'
                                ? 'text-danger'
                                : 'text-success'
                            }
                          />
                        }
                        statusColor={
                          k.status === 'error' ? 'danger' : 'success'
                        }
                      >
                        <List.Group isCardBody>
                          <List.GroupItem className="d-flex justify-content-between">
                            <span>Waiting</span>
                            <span>34</span>
                          </List.GroupItem>
                          <List.GroupItem className="d-flex justify-content-between">
                            <span>Active</span>
                            <span>34</span>
                          </List.GroupItem>
                          <List.GroupItem className="d-flex justify-content-between">
                            <span>Completed</span>
                            <span>34</span>
                          </List.GroupItem>
                          <List.GroupItem className="d-flex justify-content-between">
                            <span>Failed</span>
                            <span>34</span>
                          </List.GroupItem>
                          <List.GroupItem className="d-flex justify-content-between">
                            <span>Delayed</span>
                            <span>34</span>
                          </List.GroupItem>
                          <List.GroupItem className="d-flex justify-content-between">
                            <span>Paused</span>
                            <span>34</span>
                          </List.GroupItem>
                        </List.Group>
                      </Card>
                    </Grid.Col>
                  ))}
                </Grid.Row>

                {/* <Grid.Row card deck>
                    {businessList.map((business) => (
                      <Grid.Col key={business.id} width={12} md={6} xl={4}>
                        <Card
                          title={
                            <Media>
                              <Media.Object className="mr-3">
                                <img
                                  src={
                                    business.logo !== null
                                      ? business.logo
                                      : '/images/placeholder-company.png'
                                  }
                                  width={32}
                                />
                              </Media.Object>
                              <Media.Body>{business.name}</Media.Body>
                            </Media>
                          }
                        >
                          <Card.Body className="text-center">
                            <h5>Lifetime Leads</h5>
                            <div className="display-4">200</div>
                          </Card.Body>

                          <List.Group className="list-group-flush flex-row flex-wrap text-center">
                            <List.GroupItem className="w-50 border-right">
                              <h4 className="mb-0">6</h4>
                              <p className="mb-0 text-muted">Zip Pages</p>
                            </List.GroupItem>
                            <List.GroupItem className="w-50 border-right">
                              <h4 className="mb-0">
                                {business.campaigns.length}
                              </h4>
                              <p className="mb-0 text-muted">Campaigns</p>
                            </List.GroupItem>
                            <List.GroupItem className="w-50 border-right">
                              <h4 className="mb-0">6,500</h4>
                              <p className="mb-0 text-muted">Impressions</p>
                            </List.GroupItem>
                            <List.GroupItem className="w-50 border-right">
                              <h4 className="mb-0">650</h4>
                              <p className="mb-0 text-muted">Clicks</p>
                            </List.GroupItem>
                            <List.GroupItem className="w-50 border-right">
                              <h4 className="mb-0">$500</h4>
                              <p className="mb-0 text-muted">Spend</p>
                            </List.GroupItem>
                            <List.GroupItem className="w-50 border-right">
                              <h4 className="mb-0">$0.65</h4>
                              <p className="mb-0 text-muted">Cost per Click</p>
                            </List.GroupItem>
                            <List.GroupItem className="w-50 border-right">
                              <h4 className="mb-0">600</h4>
                              <p className="mb-0 text-muted">Conversion</p>
                            </List.GroupItem>
                            <List.GroupItem className="w-50 border-right">
                              <h4 className="mb-0">$0.20</h4>
                              <p className="mb-0 text-muted">
                                Cost per Conversion
                              </p>
                            </List.GroupItem>
                          </List.Group>
                        </Card>
                      </Grid.Col>
                    ))}
                  </Grid.Row> */}

                {/* <h2 className="card-title mb-3">Payment &amp; Subscription</h2>
                <Grid.Row card deck>
                  <Grid.Col width={12} md={6} xl={4}>
                    <Card
                      title={
                        <span>
                          <Icon
                            name="credit-card"
                            prefix="fa"
                            className="mr-2 text-muted"
                          />
                          Payment Methods
                        </span>
                      }
                      options={
                        <Button.List>
                          <Link to={``} className="btn btn-sm btn-secondary">
                            Manage
                            <Icon name="chevron-right" />
                          </Link>
                        </Button.List>
                      }
                    >
                      <Card.Body className="text-center">
                        <p className="lead">
                          Add a Card. Receive{' '}
                          <strong className="text-success">30</strong> Ad
                          Credits!
                        </p>

                        <List inline className="step-list d-none">
                          <List.Item className="completed">
                            <div className="step-icon">
                              <span className="text-line-through">Sign Up</span>
                            </div>
                          </List.Item>
                          <List.Item>
                            <div className="step-icon">Add Payment</div>
                          </List.Item>
                          <List.Item>
                            <div className="step-icon">
                              <span style={{ fontSize: '100%' }}>
                                <Icon name="gift" prefix="fa" />
                              </span>
                            </div>
                          </List.Item>
                        </List>

                        <Link to={``} className="btn btn-success mx-auto">
                          <Icon name="plus" className="mr-1" />
                          Add a Payment Method
                        </Link>

                        <p className="mt-4 mb-0 text-muted">
                          <small>
                            Your Card will NOT be charged until you've created
                            and published an Ad Campaign using the{' '}
                            <Link to={``}>Mobile App</Link>.
                          </small>
                        </p>
                      </Card.Body>
                      <Card.Footer className="text-center">
                        <p className="mb-0 text-muted">
                          <small>
                            <Icon
                              name="gift"
                              prefix="fa"
                              className="mr-2 text-success"
                            />
                            You'll receive{' '}
                            <strong className="text-dark">30 Ad Credits</strong>{' '}
                            when you add a Payment Method withing the next{' '}
                            <strong className="text-dark">24hrs</strong>.
                          </small>
                        </p>
                      </Card.Footer>
                    </Card>
                  </Grid.Col>

                  <Grid.Col width={12} md={6} xl={8}>
                    <Grid.Row className="flex-grow-1">
                      <Grid.Col width={12} sm={6} lg={4}>
                        <Card
                          title="Free"
                          options={<Tag color="success">Your Current Plan</Tag>}
                          statusColor="success"
                        >
                          <Card.Body>
                            <div className="display-3 text-center">$0</div>
                            <List unstyled className="leading-loose">
                              <List.Item>
                                <strong>3 </strong>Users
                              </List.Item>
                              <List.Item>
                                <i className="fe fe-x text-danger mr-2" />
                                Design Tools
                              </List.Item>
                              <List.Item>
                                <i className="fe fe-check text-success mr-2" />
                                Sharing Tools
                              </List.Item>
                              <List.Item>
                                <i className="fe fe-x text-danger mr-2" />
                                Private Messages
                              </List.Item>
                              <List.Item>
                                <i className="fe fe-x text-danger mr-2" />
                                Twitter API
                              </List.Item>
                            </List>
                            <div className="mt-3">
                              <Button disabled block color="secondary">
                                Switch to this Plan
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Grid.Col>
                      <Grid.Col width={12} sm={6} lg={4}>
                        <Card title="Premium">
                          <Card.Body>
                            <div className="display-3 text-center">
                              $100<span style={{ fontSize: 16 }}>/month</span>
                            </div>
                            <List unstyled className="leading-loose">
                              <List.Item>
                                <strong>3 </strong>Users
                              </List.Item>
                              <List.Item>
                                <i className="fe fe-x text-danger mr-2" />
                                Design Tools
                              </List.Item>
                              <List.Item>
                                <i className="fe fe-check text-success mr-2" />
                                Sharing Tools
                              </List.Item>
                              <List.Item>
                                <i className="fe fe-x text-danger mr-2" />
                                Private Messages
                              </List.Item>
                              <List.Item>
                                <i className="fe fe-x text-danger mr-2" />
                                Twitter API
                              </List.Item>
                            </List>
                            <div className="mt-3">
                              <Button block color="primary">
                                Switch to this Plan
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Grid.Col>
                      <Grid.Col width={12} sm={6} lg={4}>
                        <Card title="Unlimited">
                          <Card.Body>
                            <div className="display-3 text-center">
                              $200<span style={{ fontSize: 16 }}>/month</span>
                            </div>
                            <List unstyled className="leading-loose">
                              <List.Item>
                                <strong>3 </strong>Users
                              </List.Item>
                              <List.Item>
                                <i className="fe fe-x text-danger mr-2" />
                                Design Tools
                              </List.Item>
                              <List.Item>
                                <i className="fe fe-check text-success mr-2" />
                                Sharing Tools
                              </List.Item>
                              <List.Item>
                                <i className="fe fe-x text-danger mr-2" />
                                Private Messages
                              </List.Item>
                              <List.Item>
                                <i className="fe fe-x text-danger mr-2" />
                                Twitter API
                              </List.Item>
                            </List>
                            <div className="mt-3">
                              <Button block color="primary">
                                Choose This Plan
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Col>
                </Grid.Row> */}

                {/* <h2 className="card-title mb-3">Other</h2>
                <Grid.Row>
                  <Grid.Col width={12} md={6} xl={4}>
                    <Profile
                      name={user.name}
                      avatarURL="https://randomuser.me/api/portraits/men/79.jpg"
                      backgroundURL="https://www.toptal.com/designers/subtlepatterns/patterns/dark-honeycomb.png"
                      bio="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus."
                      twitterURL="twitter.com/test"
                      className=""
                    />
                  </Grid.Col>
                </Grid.Row> */}
              </Page.Content>
            )
          }}
        </Query>
      )
    }}
  </UserConsumer>
)

export default Dashboard

import * as React from 'react'
import { withFormik, FormikProps } from 'formik'
import {
  FormFeedback,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap'
import { FormCard, FormTextInput } from 'tabler-react'
import { MutationFn } from 'react-apollo'
import * as yup from 'yup'
import { StandaloneFormPage } from '../../components'
import { Signup, SignupVariables } from '../../types'

interface SignupData {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
  business: string
  urlSlug: string
}

type FormProps = {
  history: any
  signup: MutationFn<Signup, SignupVariables>
}

type Props = FormikProps<SignupData> & FormProps

class SignUpForm extends React.Component<Props> {
  render() {
    const {
      handleBlur,
      handleChange,
      handleSubmit,
      errors,
      touched,
      values,
    } = this.props

    return (
      <StandaloneFormPage>
        <FormCard
          buttonText="Create Account"
          title="Create New Account"
          onSubmit={handleSubmit}
        >
          <FormTextInput
            name="firstName"
            label="First Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            error={touched.firstName && errors.firstName}
          />

          <FormTextInput
            name="lastName"
            label="Last Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            error={touched.lastName && errors.lastName}
          />

          <FormTextInput
            name="email"
            type="email"
            label="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={touched.email && errors.email}
          />

          <FormTextInput
            name="password"
            type="password"
            label="Password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={touched.password && errors.password}
          />

          <FormTextInput
            name="confirmPassword"
            type="password"
            label="Confirm Password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.confirmPassword}
            error={touched.confirmPassword && errors.confirmPassword}
          />

          <FormTextInput
            name="business"
            label="Business Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.business}
            error={touched.business && errors.business}
          />

          <FormGroup>
            <Label for="urlSlug">Market Zipper URL</Label>
            <InputGroup>
              <Input
                type="text"
                name="urlSlug"
                id="urlSlug"
                placeholder="hustle-flow"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.urlSlug}
                invalid={!!errors.urlSlug}
              />
              <InputGroupAddon addonType="append">.mzip.co</InputGroupAddon>
              {touched.urlSlug && errors.urlSlug && (
                <FormFeedback>{errors.urlSlug}</FormFeedback>
              )}
            </InputGroup>
          </FormGroup>
        </FormCard>
      </StandaloneFormPage>
    )
  }
}

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .label('First Name'),
  lastName: yup
    .string()
    .required()
    .label('Last Name'),
  email: yup
    .string()
    .email()
    .required()
    .label('Email'),
})

export default withFormik<FormProps, SignupData>({
  mapPropsToValues() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      business: '',
      urlSlug: '',
    }
  },

  validationSchema: schema,

  async handleSubmit(values, { props, setFieldError, setSubmitting }) {
    if (values.password !== values.confirmPassword) {
      setFieldError('password', 'Please make sure both passwords match')
      setSubmitting(false)
      return
    }

    const input = {
      name: `${values.firstName} ${values.lastName}`,
      email: values.email,
      password: values.password,
      business: values.business,
      urlSlug: values.urlSlug,
    }

    const result = await props.signup({ variables: { input } })
    if (!result || !result.data) {
      throw new Error('Failed to signup new user')
    }
    if (result.data.signup.errors && result.data.signup.errors.length) {
      result.data.signup.errors.forEach(({ key, message }: any) =>
        setFieldError(key, message)
      )
      setSubmitting(false)
      return
    }

    props.history.push('/signup/complete')
  },
})(SignUpForm)

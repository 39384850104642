import * as React from 'react'
import { Link } from 'react-router-dom'
import { Card, Table } from 'tabler-react'
import { GetBusinessCalls } from '../../types'

type Props = {
  businessId: string
  calls: any //$PropertyType<GetBusinessCalls, 'calls'>,
}

const BusinessCalls = (props: Props) => {
  const { calls, businessId } = props
  return (
    <Card title="Calls">
      <div className="table-responsive">
        <Table cards>
          <thead>
            <tr>
              <th>Number</th>
              <th>Caller ID</th>
              <th>Duration</th>
              <th>When</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {calls.map((call: any) => (
              <tr key={call.id}>
                <td>{call.fromNumber}</td>
                <td>{call.callerId}</td>
                <td>{call.duration}</td>
                <td>{call.createdAt}</td>
                <td>
                  <Link to={`/businesses/${businessId}/calls/${call.id}`}>
                    Details
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Card>
  )
}

export default BusinessCalls


import * as React from 'react'
import { Button, Form, Label, FormGroup, Input } from 'reactstrap'

export default class SendNotification extends React.Component<any, any> {
  state = {
    message: '',
  }

  handleSubmit = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { message } = this.state

    await fetch('/api/notifications', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message }),
    })

    this.setState({ message: '' })
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for="newMessageText">Message</Label>
          <Input
            type="textarea"
            id="newMessageText"
            onChange={(e: any) =>
              this.setState({ message: e.currentTarget.value })
            }
            value={this.state.message}
          />
        </FormGroup>
        <Button type="submit">Send</Button>
      </Form>
    )
  }
}

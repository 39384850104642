
import * as React from 'react'
import { Card, Button, Form, Grid, List } from 'tabler-react'
import { Query, QueryResult, compose, graphql } from 'react-apollo'
import { Loading } from '../../components'
import { GetTopicKeywords } from '../../types'
import {
  GET_TOPIC_KEYWORDS_QUERY,
  CREATE_TOPIC_KEYWORD_MUTATION,
  GET_INDUSTRY_DETAILS_QUERY,
  UPDATE_KEYWORD_MUTATION,
  DELETE_KEYWORD_MUTATION,
} from './queries'
import LengthDangerMessage from './LengthDangerMessage'

class KeywordRow extends React.Component<any, any> {
  state = {
    isEditing: false,
    text: '',
  }

  enableEditing = () => {
    this.setState({ isEditing: true, text: this.props.keyword.keyword })
  }

  cancelEditing = () => {
    this.setState({ isEditing: false, text: '' })
  }

  handleChange = (e: any) => {
    this.setState({ text: e.currentTarget.value })
  }

  handleKeypress = (e: any) => {
    // ENTER KEY
    if (e.charCode === 13) {
      this.handleSave()
    }
  }

  handleSave = () => {
    const { id, keyword } = this.props.keyword
    const { text } = this.state

    if (keyword !== text) {
      this.props.onUpdateKeyword(id, text)
    }

    this.setState({ isEditing: false, text: '' })
  }

  handleDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to remove this keyword?')) {
      this.props.onDeleteKeyword(this.props.keyword.id)
    }
  }

  renderEditing() {
    const { keyword } = this.props
    return (
      <List.GroupItem
        key={keyword.id}
        className="d-flex justify-content-between align-items-center"
      >
        <input
          autoFocus
          type="text"
          className="form-control form-control-sm"
          placeholder="ex: Hot Water Tank"
          value={this.state.text}
          onChange={this.handleChange}
          onKeyPress={this.handleKeypress}
          onBlur={this.handleSave}
          style={{ marginRight: 12 }}
        />

        <Button.List>
          <Button
            key="cancel-btn"
            outline
            color="secondary"
            size="sm"
            onClick={(e: any) => e.preventDefault()}
          >
            Cancel
          </Button>
        </Button.List>
      </List.GroupItem>
    )
  }

  renderDisplay() {
    const { keyword } = this.props
    return (
      <List.GroupItem
        key={keyword.id}
        className="d-flex justify-content-between align-items-center"
      >
        <span>{keyword.keyword}</span>

        <Button.List className="text-nowrap">
          <Button
            outline
            color="secondary"
            size="sm"
            onClick={this.enableEditing}
          >
            Edit
          </Button>
          <Button
            outline
            color="danger"
            size="sm"
            icon="trash-2"
            onClick={this.handleDelete}
          />
        </Button.List>
      </List.GroupItem>
    )
  }

  render() {
    return this.state.isEditing ? this.renderEditing() : this.renderDisplay()
  }
}

class KeywordColumnInner extends React.Component<any, any> {
  state = {
    text: '',
    showLengthWarning: false,
  }

  handleTextChange = (e: any) => {
    const { value } = e.currentTarget
    this.setState({ text: value, showLengthWarning: value.length >= 26 })
  }

  handleSubmit = (e: any) => {
    e.preventDefault()
    this.props.addKeyword({
      variables: {
        topic: this.props.topicId,
        keyword: this.state.text,
      },
    })
    this.setState({ text: '' })
  }

  handleUpdateKeyword = (id: any, keyword: any) => {
    this.props.updateKeyword({
      variables: {
        id,
        keyword,
      },
    })
  }

  handleDeleteKeyword = (id: any) => {
    this.props.deleteKeyword({
      variables: { id },
    })
  }

  render() {
    const { keywords } = this.props
    return (
      <Card title="Keywords">
        <Card.Body className="p-0">
          <List.Group className="list-group-flush">
            {keywords.length < 1 && (
              <List.GroupItem className="my-5 text-center text-danger">
                There are no keywords
              </List.GroupItem>
            )}

            {keywords.map((keyword: any) => (
              <KeywordRow
                key={keyword.id}
                keyword={keyword}
                onUpdateKeyword={this.handleUpdateKeyword}
                onDeleteKeyword={this.handleDeleteKeyword}
              />
            ))}

            {/* <Table cards striped responsive>
            <Table.Body>
              {keywords.map((keyword) => (
                <KeywordRow
                  key={keyword.id}
                  keyword={keyword}
                  onUpdateKeyword={this.handleUpdateKeyword}
                  onDeleteKeyword={this.handleDeleteKeyword}
                />
              ))}
            </Table.Body>
          </Table> */}
          </List.Group>
        </Card.Body>
        <Card.Footer className="bg-light">
          <h6>Add a New Keyword</h6>
          <Form onSubmit={this.handleSubmit}>
            <Grid.Row gutters="xs">
              <Grid.Col>
                <Form.Input
                  placeholder="ex: Hot Water Tank"
                  value={this.state.text}
                  onChange={this.handleTextChange}
                />
              </Grid.Col>
              <Grid.Col auto>
                <Button color="primary" icon="plus">
                  Add
                </Button>
              </Grid.Col>
            </Grid.Row>
            {this.state.showLengthWarning && (
              <LengthDangerMessage value={this.state.text} />
            )}
          </Form>
        </Card.Footer>
      </Card>
    )
  }
}

const KeywordColumnWithMutations = compose(
  graphql(CREATE_TOPIC_KEYWORD_MUTATION, {
    name: 'addKeyword',
    // @ts-ignore
    options: ({ industryId, topicId }) => ({
      // @ts-ignore
      update(store, { data: { libraryAddTopicKeyword } }: Object) {
        const data = store.readQuery({
          query: GET_INDUSTRY_DETAILS_QUERY,
          variables: { industry: industryId },
        })

        // @ts-ignore
        if (!data || !data.libraryIndustry) {
          throw new Error('Unable to read industry data from storage')
        }

        // @ts-ignore
        const index = data.libraryIndustry.topics.findIndex(
          (x: any) => x.id === topicId
        )

        // @ts-ignore
        data.libraryIndustry.topics[index].keywords.push(
          libraryAddTopicKeyword.keyword
        )
        store.writeQuery({
          query: GET_INDUSTRY_DETAILS_QUERY,
          data,
        })
      },
    }),
  }),
  graphql(UPDATE_KEYWORD_MUTATION, { name: 'updateKeyword' }),
  graphql(DELETE_KEYWORD_MUTATION, {
    name: 'deleteKeyword',
    // @ts-ignore
    options: ({ industryId, topicId }) => ({
      // @ts-ignore
      update(store, { data: { libraryDeleteKeyword } }: Object) {
        const data = store.readQuery({
          query: GET_INDUSTRY_DETAILS_QUERY,
          variables: { industry: industryId },
        })

        // @ts-ignore
        if (!data || !data.libraryIndustry) {
          throw new Error('Unable to read industry data from storage')
        }

        // @ts-ignore
        const index = data.libraryIndustry.topics.findIndex(
          (x: any) => x.id === topicId
        )

        // @ts-ignore
        data.libraryIndustry.topics[
          index
        ].keywords = (data as any).libraryIndustry.topics[
          index
        ].keywords.filter((x: any) => x.id !== libraryDeleteKeyword.keyword.id)

        store.writeQuery({
          query: GET_INDUSTRY_DETAILS_QUERY,
          data,
        })
      },
    }),
  })
)(KeywordColumnInner)

const KeywordColumn = (props: any) => {
  const { id: industryId, topic: topicId } = props.match.params
  return (
    <Query query={GET_TOPIC_KEYWORDS_QUERY} variables={{ topic: topicId }}>
      {({ data, loading }: QueryResult<GetTopicKeywords>) => {
        if (loading && (!data || !data.libraryTopic)) return <Loading />

        if (!data || !data.libraryTopic)
          throw new Error('Unable to load industry details')

        return (
          <KeywordColumnWithMutations
            industryId={industryId}
            topicId={topicId}
            keywords={data.libraryTopic.keywords}
          />
        )
      }}
    </Query>
  )
}

export default KeywordColumn

import React, { Fragment } from 'react'
import { Card, Icon, Table } from 'tabler-react'
import { UserDashboardData_businesses_edges_node_campaigns } from '../../../types'

interface Props {
  campaigns: UserDashboardData_businesses_edges_node_campaigns[]
}

export default function CampaignStats({ campaigns }: Props) {
  return (
    <Fragment>
      <h2 className="card-title mb-2">Campaigns</h2>
      <Card>
        <div className="table-responsive">
          <Table cards highlightRowOnHover striped>
            <Table.Header>
              <Table.Row>
                <Table.ColHeader className="text-nowrap">
                  <Icon name="zap" /> Campaign
                </Table.ColHeader>
                <Table.ColHeader className="text-nowrap">
                  Channel
                </Table.ColHeader>
                <Table.ColHeader className="text-nowrap" alignContent="right">
                  <Icon name="mouse-pointer" />
                  Clicks
                </Table.ColHeader>
                <Table.ColHeader className="text-nowrap" alignContent="right">
                  <Icon name="user" /> Conversion
                </Table.ColHeader>
                <Table.ColHeader className="text-nowrap" alignContent="right">
                  <Icon name="dollar-sign" /> Cost
                </Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {campaigns.map((campaign) => (
                <Table.Row key={campaign.id}>
                  <Table.Col>{campaign.name}</Table.Col>
                  <Table.Col>Google</Table.Col>
                  <Table.Col alignContent="right">&#175;</Table.Col>
                  <Table.Col alignContent="right">&#175;</Table.Col>
                  <Table.Col alignContent="right">&#175;</Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Card>
    </Fragment>
  )
}

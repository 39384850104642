import * as React from 'react'
import { Site, List, Tag } from 'tabler-react'
import Helmet from 'react-helmet'
import { UserConsumer } from '../UserContext'
import logo from '../../assets/logo-horizontal-primary.png'
import { fullVersion } from '../../version'

export interface SubNavItem {
  value: string
  to?: string
  icon?: string
  LinkComponent?: React.ReactNode
}

export interface NavItem {
  value: string
  to?: string
  icon?: string
  active?: boolean
  LinkComponent?: React.ReactNode
  subItems?: Array<SubNavItem>
}

type Props = {
  navItems: Array<NavItem>
  children: React.ReactNode
}

const SiteWrapper = ({ navItems = [], children }: Props) => (
  <UserConsumer>
    {({ user }) => (
      <Site.Wrapper
        headerProps={{
          href: '/',
          alt: 'Market Zipper',
          imageURL: logo as any,
          accountDropdown: createAccountProps(user),
        }}
        navProps={{ itemsObjects: navItems }}
        footerProps={{
          nav: (
            <List inline className="mb-0">
              <List.Item inline>
                <a href="https://www.marketzipper.com/privacy">
                  Privacy Policy
                </a>
              </List.Item>
              <List.Item inline>
                <a href="https://www.marketzipper.com/contact">Need Help?</a>
              </List.Item>
            </List>
          ),
          note: '',
          copyright: (
            <React.Fragment>
              Copyright &copy; {new Date().getFullYear()}{' '}
              <a href="https://www.marketzipper.com/">MarketZipper</a>. All
              rights reserved.
              <div className="text-muted" style={{ textAlign: 'left' }}>
                <small>{fullVersion}</small>
              </div>
            </React.Fragment>
          ),
        }}
      >
        <Helmet defaultTitle="MarketZipper" titleTemplate="%s | MarketZipper" />
        {children}
      </Site.Wrapper>
    )}
  </UserConsumer>
)

export default SiteWrapper

function createAccountProps(user: any) {
  if (!user.authenticated) return {}
  return {
    avatarURL: `https://api.marketzipper.com/avatars/${userHash(user.email)}`,
    name: (
      <div>
        {user.name}
        <Tag color={user.role === 'admin' ? 'red' : 'lime'} className="ml-3">
          {user.role}
        </Tag>
      </div>
    ),
    // TODO fix me
    description: user.email,
    options: [
      {
        icon: 'user',
        value: 'My Account',
        to: `/user/${user.id}`,
      },
      // { icon: 'settings', value: 'Settings' },
      // { icon: 'mail', value: 'Inbox', badge: '6' },
      // { icon: 'send', value: 'Message' },
      { isDivider: true },
      {
        icon: 'help-circle',
        value: 'Need help?',
        to: 'https://www.marketzipper.com/contact',
      },
      {
        icon: 'log-out',
        value: 'Sign out',
        to: '/logout',
      },
    ],
  }
}

function userHash(value: string): number {
  var hash = 0,
    i,
    chr
  if (value.length === 0) return hash
  for (i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import SignUpForm from './SignUpForm'
import { Signup, SignupVariables } from '../../types'

const SIGNUP_MUTATION = gql`
  mutation Signup($input: SignupInput!) {
    signup(input: $input) {
      user {
        id
        email
      }
      errors {
        key
        message
      }
    }
  }
`

type Props = {
  history: any
}

export default function SignUpPage(props: Props) {
  const [signup] = useMutation<Signup, SignupVariables>(SIGNUP_MUTATION)
  return <SignUpForm signup={signup} history={props.history} />
}

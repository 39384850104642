import * as React from 'react'
import { Query, QueryResult } from 'react-apollo'
import gql from 'graphql-tag'
import { Card, Badge } from 'tabler-react'
import { Loading } from '../../components'
import {
  GetCampaignDetails,
  GetCampaignDetails_campaign,
  GetCampaignDetails_campaign_topics,
  GetCampaignDetails_campaign_topics_gawKeywords,
  GetCampaignDetails_campaign_topics_gawKeywords_expansions,
} from '../../types'
import Inspector from 'react-json-inspector'
import 'react-json-inspector/json-inspector.css'
// import { Badge } from 'reactstrap';

const GET_CAMPAIGN_DETAILS = gql`
  query GetCampaignDetails($id: ID!) {
    campaign(id: $id) {
      id
      name
      active
      costPerClick
      topics {
        id
        name
        active
        gawKeywords {
          id
          keyword
          active
          expansions {
            id
            keyword
            active
            ads {
              id
              custom
              active
            }
            variations {
              id
              keyword
              active
            }
          }
        }
      }
      zipPage {
        id
        name
        url
      }
      syncRecord
    }
  }
`

// { match: Match }
export default ({ match }: any) => (
  <Query query={GET_CAMPAIGN_DETAILS} variables={{ id: match.params.campaign }}>
    {({ loading, data }: QueryResult<GetCampaignDetails>) => {
      if (loading && (!data || !data.campaign)) return <Loading />
      if (!data || !data.campaign) throw new Error('Unable to load campaign')
      return <CampaignDetails campaign={data.campaign} />
    }}
  </Query>
)

const Status = ({ flag }: { flag: boolean }) => (
  <Badge color={flag ? 'success' : 'secondary'} className="ml-1">
    {flag ? 'Active' : 'Inactive'}
  </Badge>
)

const Section = ({ children }: { children: React.ReactNode }) => (
  <div style={{ marginBottom: 12 }}>{children}</div>
)

const SubHeading = ({ title }: { title: string }) => (
  <h5 style={{ marginTop: '0.66em' }}>{title}</h5>
)

const ExpansionList = ({
  expansions,
}: {
  expansions: GetCampaignDetails_campaign_topics_gawKeywords_expansions[]
}) => {
  if (ExpansionList.length <= 0) return null
  return (
    <ul>
      {expansions.map((expansion) => (
        <li
          key={expansion.id}
          style={{
            background: 'aliceblue',
            padding: 10,
            marginBottom: 10,
          }}
        >
          {expansion.keyword}
          <Status flag={expansion.active} />

          {expansion.variations.length > 0 && (
            <React.Fragment>
              <SubHeading title="Variations" />
              <ul>
                {expansion.variations.map((variation) => (
                  <li key={variation.id}>
                    {variation.keyword}
                    <Status flag={variation.active} />
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )}

          {expansion.ads.length > 0 && (
            <React.Fragment>
              <SubHeading title="Ads" />
              <ul>
                {expansion.ads.map((ad) => (
                  <li key={ad.id}>
                    {ad.custom ? 'Custom Ad' : 'Auto Generated Ad'}
                    <Status flag={ad.active} />
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )}
        </li>
      ))}
    </ul>
  )
}

const KeywordList = ({
  keywords,
}: {
  keywords: GetCampaignDetails_campaign_topics_gawKeywords[]
}) => {
  if (keywords.length <= 0) return null

  return (
    <ul>
      {keywords.map((keyword) => (
        <li key={keyword.id}>
          {keyword.keyword}
          <Status flag={keyword.active} />
          <ExpansionList expansions={keyword.expansions} />
        </li>
      ))}
    </ul>
  )
}

const TopicList = ({
  topics,
}: {
  topics: GetCampaignDetails_campaign_topics[]
}) => (
  <ul>
    {topics.map((topic) => (
      <li key={topic.id}>
        {topic.name}
        <Status flag={topic.active} />
        <KeywordList keywords={topic.gawKeywords} />
      </li>
    ))}
  </ul>
)

interface Props {
  campaign: GetCampaignDetails_campaign
}

const CampaignDetails = ({ campaign }: Props) => (
  <React.Fragment>
    <Card title={`Campaign: ${campaign.name || campaign.id}`}>
      <Card.Body>
        {campaign.zipPage && (
          <Section>
            <h3>Zip Page</h3>
            {campaign.zipPage.name}
            <br />
            <a href={campaign.zipPage.url}>{campaign.zipPage.url}</a>
          </Section>
        )}

        {campaign.topics.length > 0 && (
          <Section>
            <h3>Topics</h3>
            <TopicList topics={campaign.topics} />
          </Section>
        )}
        {campaign.topics.length <= 0 && <div>No topics available</div>}
      </Card.Body>
    </Card>
    <Card title="Sync Record">
      <Card.Body>
        <Inspector data={campaign.syncRecord} />
      </Card.Body>
    </Card>
  </React.Fragment>
)

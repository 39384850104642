
import * as React from 'react'
import { match, NavLink, Route } from 'react-router-dom'
import { List, Page, Grid } from 'tabler-react'
import { Query, QueryResult } from 'react-apollo'
import { Loading } from '../../components'
import { GetIndustryList } from '../../types'
import { GET_INDUSTRY_LIST_QUERY } from './queries'
import TopicTagLineColumn from './TopicTagLineColumn'
import KeywordColumn from './KeywordColumn'

const IndustryWrapper = ({ industries }: any) => (
  <Page.Content title="Library">
    <Grid.Row>
      <div className="col-md col-md-3 col-xl-2">
        {getIndustryTree(industries).map((root: any) => (
          <div key={root.id} style={{ marginBottom: '1.5rem' }}>
            <h6 className="text-uppercase">{root.name}</h6>
            <List.Group transparent>
              {root.children.map((industry: any) => (
                <List.GroupItem
                  key={industry.id}
                  className="d-flex align-items-center"
                  to={`/library/industries/${industry.id}`}
                  RootComponent={NavLink}
                >
                  {industry.name}
                </List.GroupItem>
              ))}
            </List.Group>
          </div>
        ))}
      </div>

      <div className="col-md">
        <Grid.Row cards deck>
          <div className="col-md-12 col-lg-6">
            <Route
              path="/library/industries/:id"
              render={({ match }: { match: match<{ id: string }> }) => {
                const industryId = match.params.id

                if (!industryId) {
                  throw new Error(
                    'Unable to render route without an industry id'
                  )
                }

                const industry = industries.find(
                  (x: any) => x.id === industryId
                )
                return (
                  <TopicTagLineColumn
                    industryId={industryId}
                    industry={industry}
                  />
                )
              }}
            />
          </div>

          <div className="col-md-12 col-lg-6">
            <Route
              path="/library/industries/:id/topics/:topic"
              component={KeywordColumn}
            />
          </div>
        </Grid.Row>
      </div>
    </Grid.Row>
  </Page.Content>
)

const LibraryWrapper = () => (
  <Query query={GET_INDUSTRY_LIST_QUERY}>
    {({ data, loading }: QueryResult<GetIndustryList>) => {
      if (loading && (!data || !data.industries)) return <Loading />

      if (!data || !data.industries)
        throw new Error('Unable to load industry list')

      return <IndustryWrapper industries={data.industries} />
    }}
  </Query>
)

export default LibraryWrapper

function getIndustryTree(industries: Object[]): Object[] {
  // @ts-ignore
  const roots = industries.filter((x) => x.parentId === null)
  const rootTree = roots.reduce((all, cat) => {
    // @ts-ignore
    all[cat.id] = cat
    return all
  }, {})

  const tree = industries
    // @ts-ignore
    .filter((x) => x.parentId !== null)
    .reduce((all, cat) => {
      // @ts-ignore
      if (!cat.parentId || !all[cat.parentId]) {
        throw new Error('Unable to locate parent node')
      }

      // @ts-ignore
      if (!all[cat.parentId].children) {
        // @ts-ignore
        all[cat.parentId] = {
          // @ts-ignore
          ...all[cat.parentId],
          children: [cat],
        }
      } else {
        // @ts-ignore
        all[cat.parentId].children.push(cat)
      }

      return all
    }, rootTree)

  return values(tree) as any
}

// function values<T>(obj: { [key: any]: T }): Array<T> {
//   return Object.keys(obj).map((key) => obj[key])
// }

function values(obj: object): Array<any> {
  // @ts-ignore
  return Object.keys(obj).map((key) => obj[key])
}

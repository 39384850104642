import React from 'react'
import { Redirect } from 'react-router-dom'
import { UserConsumer } from '../components'

interface Props {
  clearUser: Function
}
class Logout extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    props.clearUser()
  }

  render() {
    return <Redirect to="/login" />
  }
}

export default () => (
  <UserConsumer>
    {({ setUser }) => {
      return (
        <Logout
          clearUser={() => {
            setUser({ authenticated: false })
            try {
              localStorage.removeItem('mz/auth')
            } catch (err) {}
          }}
        />
      )
    }}
  </UserConsumer>
)

import * as React from 'react'
import { Route, NavLink, Redirect, Switch } from 'react-router-dom'
import { SiteWrapper, NavItem } from '../components'
import Dashboard from '../user/pages/UserDashboard'
import UserBusinessList from '../user/pages/UserBusinessList'
import UserBusinessDetails from '../user/pages/UserBusinessDetails'
import UserAddPaymentMethod from '../user/pages/UserAddPaymentMethod'
import Logout from '../pages/Logout'
import {
  ConnectToGoogle,
  ConnectToGoogleFailure,
  ConnectToGoogleSuccess,
  NoAdsAccountFound,
} from '../user/pages/ConnectToGoogle'

const navBarItems: Array<NavItem> = [
  {
    value: 'Dashboard',
    to: '/dashboard',
    icon: 'home',
    LinkComponent: NavLink,
  },
  {
    value: 'Businesses',
    to: '/businesses',
    icon: 'briefcase',
    LinkComponent: NavLink,
  },
]

const UserApp = () => (
  <SiteWrapper navItems={navBarItems}>
    <Switch>
      <Redirect exact from="/" to="/dashboard" />
      <Route exact path="/dashboard" component={Dashboard} />
      <Route exact path="/businesses/:id" component={UserBusinessDetails} />
      <Route exact path="/businesses" component={UserBusinessList} />
      <Route
        exact
        path="/businesses/:business/payment-methods/new"
        component={UserAddPaymentMethod}
      />

      <Route exact path="/connect-to-google" component={ConnectToGoogle} />
      <Route
        exact
        path="/connect-to-google/complete"
        component={ConnectToGoogleSuccess}
      />
      <Route
        exact
        path="/connect-to-google/failure"
        component={ConnectToGoogleFailure}
      />
      <Route
        path="/connect-to-google/no-ads-account"
        component={NoAdsAccountFound}
      />

      <Route exact path="/logout" component={Logout} />
      <Redirect from="/signup" to="/dashboard" />
      <Redirect from="/login" to="/dashboard" />
    </Switch>
  </SiteWrapper>
)

export default UserApp

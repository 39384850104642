import React from 'react'
import { Page, Grid, Card } from 'tabler-react'

interface Props {
  error?: string
}

export default function AuthErrorScreen({ error }: Props) {
  return (
    <Page.Content>
      <Page.Header>
        <Page.Title className="m-auto">An Error Occured</Page.Title>
      </Page.Header>
      <Grid.Row>
        <Grid.Col md={8} offsetMd={2} lg={6} offsetLg={3}>
          <Card statusColor="red">
            <Card.Body>
              <p>{error ? error : 'An Error Occured'}</p>
              <p>Please try again or contact support</p>
            </Card.Body>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Page.Content>
  )
}

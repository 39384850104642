import * as React from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import { Link, match } from 'react-router-dom'
import { Query, QueryResult } from 'react-apollo'
import { Page, Button, Card, Icon, Table } from 'tabler-react'
import Inspector from 'react-json-inspector'
import { Loading } from '../../components'
import { GetIncomingMailMessage } from '../../types'

import 'react-json-inspector/json-inspector.css'

const GET_INCOMING_MESSAGE_DETAILS = gql`
  query GetIncomingMailMessage($id: ID!) {
    incomingMailMessage(id: $id) {
      id
      business {
        id
        name
      }
      from
      to
      subject
      raw
      createdAt
      updatedAt
    }
  }
`

// { match: Match }
export default ({ match }: any) => (
  <Query
    query={GET_INCOMING_MESSAGE_DETAILS}
    variables={{ id: match.params.id }}
  >
    {({ loading, data }: QueryResult<GetIncomingMailMessage>) => {
      if (loading) return <Loading />
      if (!data || !data.incomingMailMessage)
        throw new Error('Unable to load message')
      return <IncomingMailDetails message={data.incomingMailMessage} />
    }}
  </Query>
)

const IncomingMailDetails = (props: any) => {
  console.log('IncomingMailDetails', props)
  const { message } = props
  return (
    <Page.Content
      title={
        <Button.List>
          <Link to={`/incoming-mail/`} className="btn btn-outline-secondary">
            <Icon name="arrow-left" /> Inbox
          </Link>
          <Link to={``} className="btn btn-primary">
            <Icon name="corner-up-left" /> Reply
          </Link>
        </Button.List>
      }
      options={
        <Button.List>
          <Link to={``} className="btn btn-outline-danger">
            <Icon name="trash" />
          </Link>
        </Button.List>
      }
      className="col-lg-8 mx-lg-auto"
    >
      <Card
        title={message.subject !== '' ? message.subject : 'No Subject'}
        options={moment(message.raw.Date).format('MMM Do, YYYY, h:mm a')}
      >
        <Card.Header className="pl-0">
          <Table cards className="table-sm table-borderless">
            <Table.Body>
              <Table.Row>
                <Table.ColHeader className="w-1">Business</Table.ColHeader>
                <Table.Col>
                  {message.business ? (
                    <Link to={`/businesses/${message.business.id}`}>
                      {message.business.name}
                    </Link>
                  ) : (
                    <span>Unknown</span>
                  )}
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.ColHeader>From:</Table.ColHeader>
                <Table.Col>{message.from}</Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.ColHeader>To:</Table.ColHeader>
                <Table.Col>{message.to}</Table.Col>
              </Table.Row>
            </Table.Body>
          </Table>
        </Card.Header>
        <Card.Body>
          <div
            dangerouslySetInnerHTML={{ __html: message.raw['stripped-html'] }}
          />
        </Card.Body>
      </Card>

      <Inspector data={message} isExpanded={() => true} />
    </Page.Content>
  )
}
